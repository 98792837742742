import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../state/actions";
import Button from "../Button/Button";

interface IDeviceProps {
    onFinish: Function;
}

const DeviceForm = (props: IDeviceProps) => {
    const [name, setName] = useState("");
    const [ip, setIp] = useState("192.168.0.1");

    const dispatch = useDispatch();
    const {createDevice} = bindActionCreators(actionCreators, dispatch);

    const createDeviceData = () => {
        const data = {
            deviceName: name,
            deviceIp: ip
        };

        return createDevice(data);
    }

    return (
        <div className={"shadow"}>
            <div className="container">
                <h2>Добавить устройство</h2>
                <div className="form-component">
                    <input type="text" className={"form-input"} placeholder={"Название устройства"} defaultValue={name}
                           onInput={e => setName(e.currentTarget.value)}/>
                    <input type="text" className={"form-input"} placeholder={"192.168.0.1"} defaultValue={ip}
                           onInput={e => setIp(e.currentTarget.value)}/>

                    <Button value={"Сохранить"} onClick={() => {
                        new Promise((resolve) => {
                            resolve(createDeviceData());
                        }).then(() => {
                                props.onFinish();
                            });
                    }}/>
                    <Button value={"отмена"} onClick={() => {
                        props.onFinish()
                    }}/>
                </div>
            </div>
        </div>
    );
};

export default DeviceForm;

/* tslint:disable */
/* eslint-disable */
/**
 * Device.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../../runtime';
import type {
  CreateDevice200Response,
  CreateDeviceRequest,
  FindDevices,
  ServerError,
  UpdateDeviceRequest,
} from '../models';
import {
    CreateDevice200ResponseFromJSON,
    CreateDevice200ResponseToJSON,
    CreateDeviceRequestFromJSON,
    CreateDeviceRequestToJSON,
    FindDevicesFromJSON,
    FindDevicesToJSON,
    ServerErrorFromJSON,
    ServerErrorToJSON,
    UpdateDeviceRequestFromJSON,
    UpdateDeviceRequestToJSON,
} from '../models';

export interface CreateDeviceOperationRequest {
    body: CreateDeviceRequest;
}

export interface DeleteDeviceRequest {
    deviceId: string;
}

export interface FindDevicesRequest {
    deviceIpFilter?: string;
    deviceIdFilter?: Array<string>;
    deviceNameFilter?: string;
    tokenFilter?: string;
    limit?: number;
    page?: number;
    unscoped?: boolean;
}

export interface UpdateDeviceOperationRequest {
    body: UpdateDeviceRequest;
}

/**
 * 
 */
export class DeviceApi extends runtime.BaseAPI {

    /**
     * Create Device
     */
    async createDeviceRaw(requestParameters: CreateDeviceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDevice200Response>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/device`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDeviceRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDevice200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Device
     */
    async createDevice(requestParameters: CreateDeviceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDevice200Response> {
        const response = await this.createDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Device
     */
    async deleteDeviceRaw(requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deleteDevice.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/device`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Device
     */
    async deleteDevice(requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Find Devices
     */
    async findDevicesRaw(requestParameters: FindDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindDevices>> {
        const queryParameters: any = {};

        if (requestParameters.deviceIpFilter !== undefined) {
            queryParameters['device_ip_filter'] = requestParameters.deviceIpFilter;
        }

        if (requestParameters.deviceIdFilter) {
            queryParameters['device_id_filter'] = requestParameters.deviceIdFilter;
        }

        if (requestParameters.deviceNameFilter !== undefined) {
            queryParameters['device_name_filter'] = requestParameters.deviceNameFilter;
        }

        if (requestParameters.tokenFilter !== undefined) {
            queryParameters['token_filter'] = requestParameters.tokenFilter;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.unscoped !== undefined) {
            queryParameters['unscoped'] = requestParameters.unscoped;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/device`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindDevicesFromJSON(jsonValue));
    }

    /**
     * Find Devices
     */
    async findDevices(requestParameters: FindDevicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindDevices> {
        const response = await this.findDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Device
     */
    async updateDeviceRaw(requestParameters: UpdateDeviceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/device`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDeviceRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Device
     */
    async updateDevice(requestParameters: UpdateDeviceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Validate Device Token
     */
    async validateDeviceTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["device_token"] = this.configuration.apiKey("device_token"); // DeviceAuth authentication
        }

        const response = await this.request({
            path: `/device/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate Device Token
     */
    async validateDeviceToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateDeviceTokenRaw(initOverrides);
    }

}

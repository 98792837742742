import {GET_SESSIONS} from "../actionTypes";
import {FindSessions200Response} from "../../api/session";

const initialState: FindSessions200Response = {
  maxPages: 0,
  sessions: []
}

const sessionListReducer = (state: FindSessions200Response = initialState, action: SessionAction) => {
  switch (action.type) {
    case GET_SESSIONS:
      return {...state, ...action.payload};
    default:
      return state;
  }
}

export default sessionListReducer;

import {PlayerStatistics} from "../api/player_statistics";

export const MODEL_PARAMS: string[] = [
    "cellSize",
    "speedOfSpreading",
    "cellWaterToEmpty",
    "cellWaterToSmolder",
    "whenSecondPhaseStartsSeconds"
];

export const MODEL_PARAMS_LABELS: any = {
    cellSize: "Размер ячейки",
    speedOfSpreading: "скорость распространения пожара",
    cellWaterToSmolder: "кол-во тушащего вещества для ПРЕКРАЩЕНИЯ ГОРЕНИЯ",
    cellWaterToEmpty: "кол-во тушащего вещества для ПРЕКРАЩЕНИЯ распространение огня",
    whenSecondPhaseStartsSeconds: "время до второй фазы",
    startingPlayersOxygen: "количество кислорода"
}

export const MODEL_PARAMS_VALUES: any = {
    cellWaterToEmpty: {
        step: 1,
        min: 10,
        max: 1000,
        recommended: 50
    },
    cellSize: {
        step: 0.1,
        min: 0.1,
        max: 0.5,
        recommended: 0.1
    },
    speedOfSpreading: {
        step: 0.001,
        min: 0.001,
        max: 0.02,
        recommended: 0.002
    },
    cellWaterToSmolder: {
        step: 1,
        min: 5,
        max: 1000,
        recommended: 228
    },
    whenSecondPhaseStartsSeconds: {
        step: 10,
        min: 6,
        max: 6000,
        recommended: 600
    },
    startingPlayersOxygen: {
        max: 50000
    }
}

export const MODEL_DEFAULT_SETTINGS: any = {
    cellSize: 0.1,
    speedOfSpreading: 0.002,
    whenSecondPhaseStartsSeconds: 600,
    cellWaterToEmpty: 50,
    cellWaterToSmolder: 228,
    startingPlayersOxygen: 50000
};

export const DEFAULT_END_DATA: PlayerStatistics = {
    sessionId: "",
    sessionName: "",
    startTime: new Date(),
    endTime: new Date(),
    fullTime: 0,
    sessionConfig: {
        cellSize: 0,
        cellWaterToSmolder: 0,
        cellWaterToEmpty: 0,
        speedOfSpreading: 0,
        startingPlayersOxygen: 0,
        whenSecondPhaseStartsSeconds: 0
    },
    userData: {
        username: "",
        position: {
            x: 0,
            y: 0,
            z: 0
        },
        oxygenLeft: 0,
        heartRate: 0,
        waterSpent: 0,
        userId: 0,
    }
}

export const MINMAX_FILTER_VALUES = {
    fullTime: {
        icon: "time",
        values: [0, 6000], step: 60, minmax: [0, 6000],
    },
    oxygenLeft: {
        icon: "oxygen",
        values: [0, 250000], step: 100, minmax: [0, 250000],
    },
    waterSpent: {
        icon: "liquid",
        values: [0, 10000], step: 100, minmax: [0, 10000],
    },
    heartRate: {
        icon: "heartRate",
        values: [50, 200], step: 1, minmax: [50, 200],
    },
    cellSize: {
        icon: "size",
        values: [0.1, 0.5], step: 0.1, minmax: [0.1, 0.5],
    },
    speedOfSpreading: {
        icon: "speed",
        values: [0.001, 0.2], step: 0.001, minmax: [0.001, 0.2],
    },
    whenSecondPhaseStartsSeconds: {
        icon: "timeout",
        values: [6, 6000], step: 10, minmax: [6, 6000],
    },
    cellWaterToEmpty: {
        icon: "hold",
        values: [5, 1000], step: 1, minmax: [5, 1000],
    },
    cellWaterToSmolder: {
        icon: "stop",
        values: [5, 1000], step: 1, minmax: [5, 1000],
    },
};

export const DEFAULT_STOPFIRE_LOCALSTORAGE = {
    userdata: {
        accessToken: "",
        refreshToken: "",
        accessExpiresIn: 0,
        refreshExpiresIn: 0,
    },
    flame: MODEL_DEFAULT_SETTINGS,
    paginationLimit: 10,
    filters: {
        startTimeFrom: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString('ru-ru'),
        startTimeTo: new Date().toLocaleDateString("ru-RU"),
        ...MINMAX_FILTER_VALUES
    }
};

import {DEFAULT_STOPFIRE_LOCALSTORAGE} from "./modelParamsConstants";

export const msToTime = (duration: number) => {
  if (!duration) {
    return "--:--";
  }

  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let timeValue = ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);

  if (duration > 3600000) {
    let hours = Math.floor((duration / (1000 * 60 * 60)));
    timeValue = ("0" + hours).slice(-2) + ":" + timeValue;
  }

  return timeValue;
}

export const secToTime = (initial: number) => new Date(initial * 1000).toISOString().substr(11, 8);

export function translate(str: string) {
  const letters = {
    "а": "a",
    "б": "b",
    "в": "v",
    "г": "g",
    "д": "d",
    "е": "e",
    "ё": "yo",
    "ж": "zh",
    "з": "z",
    "и": "i",
    "й": "j",
    "к": "k",
    "л": "l",
    "м": "m",
    "н": "n",
    "о": "o",
    "п": "p",
    "р": "r",
    "с": "s",
    "т": "t",
    "у": "u",
    "ф": "f",
    "х": "h",
    "ц": "c",
    "ч": "ch",
    "ш": "w",
    "щ": "shh",
    "ъ": "''",
    "ы": "y",
    "ь": "'",
    "э": "e",
    "ю": "yu",
    "я": "ya"
  }
  let translated = "";

  for (let letter of str.toLocaleLowerCase()) {
    // @ts-ignore
    translated += letters[letter] ?? letter;
  }

  return translated;
}

export const getRole = (user: string) => user === "user" ? "Ученик" : user === 'moderator' ? "Преподаватель" : "Администратор";

export const makeParams = (defaultConfig: any, config: any) => {
  return {
    ...defaultConfig,
    ...config
  }
}

export const getInputtedFlameConfig = () => getStopFire("flame");

export const resetTokenRequest = async (canRefresh = false) => {
  // @ts-ignore
  if (window.requestSent)
    return;

  let token = getStopFire("userdata");
  token = token.refresh_token ?? "";

  // @ts-ignore
  window.requestSent = true;

  const API_URL = localStorage.getItem('stopfireurl');
  return await fetch(`${API_URL}/user_administrator/login`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      "user_token": token
    }),
    body: JSON.stringify({})
  }).then(res => res.json())
    .then(res => {
      // @ts-ignore
      window.requestSent = false;

      if (res.accessToken) {
        let oldValue = getStopFire("userdata");

        setStopFire("userdata", {
          ...oldValue,
          logged_at: Date.now(),
          accessToken: res.accessToken
        });

        if (canRefresh) {
          window.location.reload();
        }
        return res;
      } else {
        console.log(res.message);
        localStorage.removeItem("stopfire");

        if (canRefresh) {
          window.location.reload();
        }

        return null;
      }
    });
}

export const copyToClipboard = (what: string) => {
  let input = document.createElement("input");
  input.className = "temp";
  input.value = what;
  document.body.append(input);
  input.select();
  document.execCommand('copy');
  input.remove();
}

export const setStopFire = (property: string, data: any) => {
  localStorage.setItem("stopfire", JSON.stringify({
    ...JSON.parse(localStorage.getItem("stopfire") as string),
    [property]: data
  }));
}

export const getStopFire = (property: string) => JSON.parse(localStorage.getItem("stopfire") as string)[property];

export const resetStopFire = () => localStorage.setItem("stopfire", JSON.stringify(DEFAULT_STOPFIRE_LOCALSTORAGE));

export const removeEmptyKeys = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)
      && (typeof obj[key] !== "number" && (!obj[key] || !obj[key].length))
      && key !== 'config'
    ) {
      delete obj[key];
    }
  }

  return obj;
}

export const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1, word.length);

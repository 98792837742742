/* tslint:disable */
/* eslint-disable */
/**
 * User Administrator.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface PasswordLogin
 */
export interface PasswordLogin {
    /**
     *
     * @type {string}
     * @memberof PasswordLogin
     */
    loginDataType: string;
    /**
     *
     * @type {string}
     * @memberof PasswordLogin
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof PasswordLogin
     */
    username: string;
}

/**
 * Check if a given object implements the PasswordLogin interface.
 */
export function instanceOfPasswordLogin(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "loginDataType" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "username" in value;

    return isInstance;
}

export function PasswordLoginFromJSON(json: any): PasswordLogin {
    return PasswordLoginFromJSONTyped(json, false);
}

export function PasswordLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'loginDataType': json['login_data_type'],
        'password': json['password'],
        'username': json['username'],
    };
}

export function PasswordLoginToJSON(value?: PasswordLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'login_data_type': value.loginDataType,
        'password': value.password,
        'username': value.username,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Fire Model Master Node.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { State } from './State';
import {
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './State';

/**
 * 
 * @export
 * @interface UpdateStateRequest
 */
export interface UpdateStateRequest {
    /**
     * 
     * @type {State}
     * @memberof UpdateStateRequest
     */
    newState: State;
    /**
     * 
     * @type {string}
     * @memberof UpdateStateRequest
     */
    sessionId: string;
}

/**
 * Check if a given object implements the UpdateStateRequest interface.
 */
export function instanceOfUpdateStateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newState" in value;
    isInstance = isInstance && "sessionId" in value;

    return isInstance;
}

export function UpdateStateRequestFromJSON(json: any): UpdateStateRequest {
    return UpdateStateRequestFromJSONTyped(json, false);
}

export function UpdateStateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newState': StateFromJSON(json['new_state']),
        'sessionId': json['session_id'],
    };
}

export function UpdateStateRequestToJSON(value?: UpdateStateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_state': StateToJSON(value.newState),
        'session_id': value.sessionId,
    };
}


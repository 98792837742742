import React, {SyntheticEvent, useEffect, useState} from 'react';
import logo from "../../images/logo.svg";
import "./Auth.css";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from '../../state/actions';

const Auth = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const {authorization} = useSelector((state: any) => state);
  const {loginAction} = bindActionCreators(actionCreators, dispatch);

  const submitForm = (e: SyntheticEvent) => {
    e.preventDefault();

    if (login && password) {
      loginAction({
        loginDataType: "password_login",
        username: login,
        password
      })
    }
  };

  useEffect(() => {
    if (authorization.message) {
      setOpen(true);
    }
  }, [authorization]);

  return (<>
    <div className={"auth page"}>
      <img src={logo} alt="logo" width={400}/>

      <form className={'column'} action="#" onSubmit={submitForm}>
        <input className={"form-input"} type={'text'} placeholder={"Логин"} onInput={(e) => {
          setLogin(e.currentTarget.value);
        }}/>
        <input className={"form-input"} type={'password'} placeholder={"Пароль"} onInput={(e) => {
          setPassword(e.currentTarget.value);
        }}/>
        <Button value={"Войти"} disabled={!login || !password}/>
      </form>
    </div>
    {authorization.message && open && <Alert title={"Неверный логин или пароль!"} onOk={() => {
      setOpen(false);
    }}/>}
  </>);
};

export default Auth;

import {GET_FIRE_MODEL_PLAYER, RESET_FIRE_MODEL_PLAYER} from "../actionTypes";
import {Player} from "../../api/player_statistics";

const initialState: Player = {
  heartRate: 0,
  oxygenLeft: 0,
  position: {x: 0, y: 0, z: 0},
  userId: 0,
  waterSpent: 0,
  username: "",
};

const fireModelPlayerReducer = (state: Player = initialState, action: FireModelPlayerAction) => {
  switch (action.type) {
    case GET_FIRE_MODEL_PLAYER:
      let newData = action.payload.players?.length ? action.payload.players[0] : [];
      return {...state, ...newData};
    case RESET_FIRE_MODEL_PLAYER:
      return {...initialState};
    default:
      return state;
  }
}

export default fireModelPlayerReducer;

import {SET_ACTIVE_TAB, SET_IS_FULL} from "../actionTypes";

const initialState:MenuTabState = {
    active: 0,
    isFull: true
};

const menuReducer = (state: MenuTabState = initialState, action: MenuTabAction) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return {
                ...state,
                active: action.id
            };
        case SET_IS_FULL:
            return {
                ...state,
                isFull: action.isFull
            }
        default:
            return state;
    }
}

export default menuReducer;
import React, {useState} from 'react';
import Button from "../../components/Button/Button";
import "./SessionSettings.css";
import {
  MODEL_DEFAULT_SETTINGS,
  MODEL_PARAMS,
  MODEL_PARAMS_LABELS,
  MODEL_PARAMS_VALUES,
} from "../../constants/modelParamsConstants"
import RangeSlider from "../../components/RangeSlider/RangeSlider";
import oxygen from "../../images/icons/info/oxygen_gray.svg";
import {useScale} from "../../state/hooks/useScale";
import {getStopFire, setStopFire} from "../../constants/utilFunctions";
import {useSelector} from "react-redux";
import {User} from "../../api/user_administrator";
import {Device} from "../../api/device";

const SessionSettings = (props: any) => {
  let localSettings = props.config ?? getStopFire("flame");

  const {users, devices} = useSelector((state: any) => state);
  const [flameSettings, setFlameSettings] = useState(localSettings ?? MODEL_DEFAULT_SETTINGS);
  const scale = useScale(900, 1200);

  const setNewValue = (newValue: any) => {
    // @ts-ignore
    window.hasChangedConfig = true;

    setFlameSettings({
      ...flameSettings,
      ...newValue
    });

    setStopFire("flame", {
      ...flameSettings,
      ...newValue
    });
  }

  let content = MODEL_PARAMS.map((type: string) =>
    <RangeSlider key={type}
                 icon={type}
                 header={MODEL_PARAMS_LABELS[type]}
                 onChange={(value: number) => {
                   let val: any = {}
                   val[type] = value;
                   setNewValue(val)
                 }}
                 min={MODEL_PARAMS_VALUES[type].min}
                 max={MODEL_PARAMS_VALUES[type].max}
                 defaultValue={flameSettings[type]}
                 recommendedValue={MODEL_PARAMS_VALUES[type].recommended}
                 step={MODEL_PARAMS_VALUES[type].step}
    />
  );

  const findUserFullName = () => {
    const propsUserId = props.usersWithDevices[0].userId;
    if (propsUserId) {
      let username = users.users.find(((el: User) => el.userId === propsUserId));
      return username ? username.fullName : "";
    }
    return props.usersWithDevices[0]?.user?.fullName || "";
  }

  const findDeviceIp = () => {
    const propsDeviceId = props.usersWithDevices[0].deviceId;
    if (propsDeviceId) {
      let theDevice = devices.devices.find((el: Device) => el.deviceId === propsDeviceId);
      return theDevice ? theDevice.deviceIp : "";
    }

    return props.usersWithDevices[0]?.device?.deviceIp || "";
  }

  let username = findUserFullName();
  if (username) {
    username = <span className={"username"}>
            {username.replace(/ /, "\n")
              .split("\n")
              .map((el: string, i: number) => <React.Fragment key={i}>
                  {el}<br/>
                </React.Fragment>
              )}
        </span>;
  }
  let theDevice = findDeviceIp();

  return (
    <div className={"sessionSettings"} style={{
      transform: `translate(-50%, -50%) scale(${scale})`
    }}>
      <header>
        <div className="controls">
          <Button value={username || "Добавить пользователя"} icon={"userpic"} onClick={props.setUser}/>
          <Button value={theDevice || "Добавить устройство"} icon={"vr"} onClick={props.setDevice}/>
        </div>
        <div className="save">
          <Button value={"Сохранить"} onClick={props.onSave}/>
          <Button value={"X"} onClick={props.onClose}/>
        </div>
      </header>
      <main className={"content"}>
        <div className={"flame-settings"}>
          {content}
        </div>
        <div className="oxygen">
          <label>
            <img src={oxygen} alt="oxygen"/>
            <input type="number" className={"form-input"} placeholder={"Кол-во кислорода"}
                   defaultValue={flameSettings.startingPlayersOxygen}
                   onInput={e => {
                     setNewValue({
                       startingPlayersOxygen: +e.currentTarget.value
                     })
                   }}/>
          </label>
        </div>
      </main>
    </div>
  );
};

export default SessionSettings;

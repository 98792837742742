import {useEffect, useState} from "react";

export const useScale = (contentHeight: number, contentWidth: number) => {
    const [scale, setScale] = useState(1);

    useEffect(() => {
        resizeContent();
        window.addEventListener("resize", resizeContent)

        return () => window.removeEventListener("resize", resizeContent);
    }, []);

    function resizeContent() {
        if (window.innerHeight >= 1080) {
            setScale(1);
            return;
        }

        setScale(Math.min(window.innerWidth / contentWidth, window.innerHeight / contentHeight));
    }

    return scale;
}

import TableLine from "../../components/TableLine/TableLine";
import {Pagination} from "../../components/Pagination/Pagination";
import React, {useEffect, useState} from "react";
import {getRole} from "../../constants/utilFunctions";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../state/actions";
import {Loader} from "../../components/Loader/Loader";
import {FindUsers200Response, User} from "../../api/user_administrator";

const UserList = (props: any) => {
  const [data, setData] = useState<FindUsers200Response>({
    maxPages: 0,
    users: []
  });
  const [loading, setLoading] = useState(true);

  const {users} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const {getUsers} = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    setData(users);
  }, [users]);

  const filteredData = () => {
    return data.users.map((el: User) => {
      const {userId, fullName, userRole} = el;

      return [
        userId?.slice(0, 5) ?? "",
        fullName,
        getRole(userRole),
        {
          icon: "remove",
          className: "icon-13",
          onClick: () => {
            props.setDeletePlayer(el);
          }
        },
        {
          icon: "edit",
          className: "icon-13",
          onClick: () => {
            props.setEditPlayer(el);
          }
        }
      ];
    });
  }
  let columns = "0.5fr 3fr 2.8fr 0.1fr 0.1fr";

  return <>
    <div className="table">
      <TableLine data={['id', 'Пользователь', "Роль", ""]} isHeader={true} columns={columns}/>
      <div className="table__container">
        {filteredData().map((el: any, i: number) => <TableLine data={el} key={i} columns={columns}/>)}
      </div>
    </div>
    {loading && <Loader/>}
    <Pagination max_pages={data.maxPages}
                onChange={(limit: number, page: number) => {
                  new Promise((resolve) => {
                    setLoading(true);
                    resolve(getUsers({limit, page}))
                  }).finally(() => {
                    setLoading(false);
                  });
                }}/>
  </>;
};

export default UserList;

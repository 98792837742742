import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import "./AdminPanel.css"
import Button from "../../components/Button/Button";
import {useDispatch} from "react-redux";
import PlayerForm from "../../components/PlayerForm/PlayerForm";
import FilterData from "../../components/FilterData/FilterData";
import {bindActionCreators} from "redux";
import {actionCreators} from '../../state/actions';
import Alert from "../../components/Alert/Alert";
import UserList from "../../layouts/UserList/UserList";
import {User} from '../../api/user_administrator';

const AdminPanel = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [editPlayer, setEditPlayer] = useState<User | undefined>(undefined);
  const [deletePlayer, setDeletePlayer] = useState<User | undefined>(undefined);
  const [alertText, setAlertText] = useState<any>(null);

  const dispatch = useDispatch();
  const {getUsers, deleteUser, setActiveTab} = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    setActiveTab(0);
  }, []);

  const showAlert = (data: any) => {
    setAlertText(data);
  }

  return (
    <main className={"admin-panel page"}>
      <Sidebar/>
      <main>
        <header>
          <Button value={"Добавить пользователя"} onClick={() => {
            setIsAdd(true);
          }}/>

          <FilterData filterField={"fullNameFilter"} filterFunction={getUsers}/>
        </header>
        <UserList setDeletePlayer={setDeletePlayer} setEditPlayer={setEditPlayer}/>
      </main>

      {isAdd && <PlayerForm isEdit={false} showAlert={showAlert} onFinish={() => {
        setIsAdd(false);
        getUsers();
      }}/>}
      {editPlayer && <PlayerForm isEdit={true} player={editPlayer} onFinish={() => {
        setEditPlayer(undefined);
        getUsers();
      }}/>}
      {deletePlayer && <Alert title={`Вы уверены, что хотите удалить пользователя?`} okText={"Подтвердить"}
                              onOk={() => {
                                new Promise((resolve) => {
                                  resolve(deleteUser({
                                    userId: deletePlayer.userId
                                  }));
                                }).then(() => {
                                  setDeletePlayer(undefined);
                                  getUsers();
                                }).catch(() => {
                                  setDeletePlayer(undefined);
                                  showAlert({
                                    title: "Ошибка удаления",
                                    html: "Данный пользователь находится в активной сессии"
                                  });
                                })
                              }}
                              cancelText={"Отмена"} onCancel={() => {
        setDeletePlayer(undefined);
      }}
                              maxWidth={376}
      />}
      {alertText && <Alert title={alertText.title} html={alertText.html} lowerCase={true}
                           onOk={() => {
                             setAlertText(null);
                           }}/>}
    </main>
  );
};

export default AdminPanel;

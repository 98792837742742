/* tslint:disable */
/* eslint-disable */
/**
 * Session.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { ActiveRole } from './ActiveRole';
import {
    ActiveRoleFromJSON,
    ActiveRoleFromJSONTyped,
    ActiveRoleToJSON,
} from './ActiveRole';
import type { Config } from './Config';
import {
    ConfigFromJSON,
    ConfigFromJSONTyped,
    ConfigToJSON,
} from './Config';
import type { State } from './State';
import {
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './State';
import type { UpdateMap } from './UpdateMap';
import {
    UpdateMapFromJSON,
    UpdateMapFromJSONTyped,
    UpdateMapToJSON,
} from './UpdateMap';
import type { UserWithDeviceID } from './UserWithDeviceID';
import {
    UserWithDeviceIDFromJSON,
    UserWithDeviceIDFromJSONTyped,
    UserWithDeviceIDToJSON,
} from './UserWithDeviceID';

/**
 * 
 * @export
 * @interface UpdateSession
 */
export interface UpdateSession {
    /**
     * 
     * @type {string}
     * @memberof UpdateSession
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSession
     */
    addModeratorIds?: Array<string>;
    /**
     * 
     * @type {Array<UserWithDeviceID>}
     * @memberof UpdateSession
     */
    addUserWithDevicesIds?: Array<UserWithDeviceID>;
    /**
     * 
     * @type {Array<UserWithDeviceID>}
     * @memberof UpdateSession
     */
    deleteUserWithDevicesIds?: Array<UserWithDeviceID>;
    /**
     * 
     * @type {Config}
     * @memberof UpdateSession
     */
    config?: Config;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSession
     */
    deleteModeratorIds?: Array<string>;
    /**
     * 
     * @type {ActiveRole}
     * @memberof UpdateSession
     */
    newActiveRole?: ActiveRole;
    /**
     * 
     * @type {string}
     * @memberof UpdateSession
     */
    newName?: string;
    /**
     * 
     * @type {State}
     * @memberof UpdateSession
     */
    newState?: State;
    /**
     * 
     * @type {UpdateMap}
     * @memberof UpdateSession
     */
    updateMap?: UpdateMap;
}

/**
 * Check if a given object implements the UpdateSession interface.
 */
export function instanceOfUpdateSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function UpdateSessionFromJSON(json: any): UpdateSession {
    return UpdateSessionFromJSONTyped(json, false);
}

export function UpdateSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'addModeratorIds': !exists(json, 'add_moderator_ids') ? undefined : json['add_moderator_ids'],
        'addUserWithDevicesIds': !exists(json, 'add_user_with_devices_ids') ? undefined : ((json['add_user_with_devices_ids'] as Array<any>).map(UserWithDeviceIDFromJSON)),
        'deleteUserWithDevicesIds': !exists(json, 'delete_user_with_devices_ids') ? undefined : ((json['delete_user_with_devices_ids'] as Array<any>).map(UserWithDeviceIDFromJSON)),
        'config': !exists(json, 'config') ? undefined : ConfigFromJSON(json['config']),
        'deleteModeratorIds': !exists(json, 'delete_moderator_ids') ? undefined : json['delete_moderator_ids'],
        'newActiveRole': !exists(json, 'new_active_role') ? undefined : ActiveRoleFromJSON(json['new_active_role']),
        'newName': !exists(json, 'new_name') ? undefined : json['new_name'],
        'newState': !exists(json, 'new_state') ? undefined : StateFromJSON(json['new_state']),
        'updateMap': !exists(json, 'update_map') ? undefined : UpdateMapFromJSON(json['update_map']),
    };
}

export function UpdateSessionToJSON(value?: UpdateSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'add_moderator_ids': value.addModeratorIds,
        'add_user_with_devices_ids': value.addUserWithDevicesIds === undefined ? undefined : ((value.addUserWithDevicesIds as Array<any>).map(UserWithDeviceIDToJSON)),
        'delete_user_with_devices_ids': value.deleteUserWithDevicesIds === undefined ? undefined : ((value.deleteUserWithDevicesIds as Array<any>).map(UserWithDeviceIDToJSON)),
        'config': ConfigToJSON(value.config),
        'delete_moderator_ids': value.deleteModeratorIds,
        'new_active_role': ActiveRoleToJSON(value.newActiveRole),
        'new_name': value.newName,
        'new_state': StateToJSON(value.newState),
        'update_map': UpdateMapToJSON(value.updateMap),
    };
}


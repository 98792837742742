import {applyMiddleware, compose, createStore} from "@reduxjs/toolkit";
import {combineReducers} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import menuReducer from "./reducers/menuReducer";
import userReducer from "./reducers/userReducer";
import authorizationReducer from "./reducers/authorizationReducer";
import devicesReducer from "./reducers/devicesReducer";
import sessionListReducer from "./reducers/sessionListReducer";
import currentSessionReducer from "./reducers/currentSessionReducer";
import playerStatisticsReducer from "./reducers/playerStatisticsReducer";
import fireModelPlayerReducer from "./reducers/fireModelPlayerReducer";
import {getStopFire} from "../constants/utilFunctions";
import {FindUsers200Response, Login200Response} from "../api/user_administrator";
import {FindDevices} from "../api/device";
import {FindSessions200Response} from "../api/session";
import {FindPlayersStatistic200Response} from "../api/player_statistics";
import {Player} from "../api/master_node";

export const reducers = combineReducers({
  menu: menuReducer,
  authorization: authorizationReducer,
  users: userReducer,
  devices: devicesReducer,
  sessionList: sessionListReducer,
  currentSession: currentSessionReducer,
  playerStatistics: playerStatisticsReducer,
  fireModelPlayer: fireModelPlayerReducer
});

const initialMenu: MenuTabState = {
  active: 0,
  isFull: false
}

const initialAuthorization: Login200Response = localStorage.getItem("stopfire") ? getStopFire("userdata") : {
  accessExpiresIn: 0,
  accessToken: "",
  refreshExpiresIn: 0,
  refreshToken: "",
  message: "",
  role: "",
  fullName: ""
}

const initialUsers: FindUsers200Response = {
  maxPages: 0,
  users: []
};

const initialDevices: FindDevices = {
  maxPages: 0,
  devices: []
};

const initialSessions: FindSessions200Response = {
  maxPages: 0,
  sessions: []
};

const initialCurrentSession: CurrentSession = {
  id: "",
  state: "",
  activeRole: "",
  user: {
    userId: "",
    fullName: "",
  },
  device: {
    ip: "",
    name: "",
    token: "",
  },
  config: {}
};

const initialPlayerStatistics: FindPlayersStatistic200Response = {
  maxPages: 0,
  playerStatistics: []
};

const initialFireModelPlayer: Player = {
  endTime: new Date(),
  fullTime: 0,
  heartRate: 0,
  oxygenLeft: 0,
  position: {x: 0, y: 0, z: 0},
  startTime: new Date(),
  userId: 0,
  waterSpent: 0,
};

const initialState: any = {
  menu: initialMenu,
  authorization: initialAuthorization,
  users: initialUsers,
  devices: initialDevices,
  sessionList: initialSessions,
  currentSession: initialCurrentSession,
  playerStatistics: initialPlayerStatistics,
  fireModelPlayer: initialFireModelPlayer
}

const rootReducer = (state: any, action: any) => {
  return reducers(state, action);
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk)),
);

export type AppDispatch = typeof store.dispatch

import calendar from "../images/icons/info/calendar.svg";
import heartRate from "../images/icons/info/heart_rate.svg";
import hold from "../images/icons/info/cell_water_to_empty.svg";
import liquid from "../images/icons/info/liquid.svg";
import oxygen from "../images/icons/info/starting_players_oxygen.svg";
import size from "../images/icons/info/cell_size.svg";
import speed from "../images/icons/info/speed_of_spreading.svg";
import stop from "../images/icons/info/cell_water_to_smolder.svg";
import time from "../images/icons/info/time.svg";
import timeout from "../images/icons/info/when_second_phase_starts_seconds.svg";
import edit from "../images/icons/info/edit.svg";
import edit_disabled from "../images/icons/info/edit_disabled.svg";
import remove from "../images/icons/info/remove.svg";
import remove_disabled from "../images/icons/info/remove_disabled.svg";
import right from "../images/icons/info/right.svg";
import right_disabled from "../images/icons/info/right_disabled.svg";
import copy from "../images/icons/info/copy.svg";

const INFO_ICONS:any = {
    calendar,
    heartRate,
    hold,
    liquid,
    oxygen,
    size,
    speed,
    stop,
    time,
    timeout,
    edit,
    edit_disabled,
    remove,
    remove_disabled,
    right,
    right_disabled,
    copy
}

export default INFO_ICONS;

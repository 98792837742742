/* tslint:disable */
/* eslint-disable */
/**
 * Fire Model Master Node.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { InitModel } from './InitModel';
import {
    InitModelFromJSON,
    InitModelFromJSONTyped,
    InitModelToJSON,
} from './InitModel';

/**
 * 
 * @export
 * @interface CreateFireModelRequest
 */
export interface CreateFireModelRequest {
    /**
     * 
     * @type {InitModel}
     * @memberof CreateFireModelRequest
     */
    model: InitModel;
}

/**
 * Check if a given object implements the CreateFireModelRequest interface.
 */
export function instanceOfCreateFireModelRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "model" in value;

    return isInstance;
}

export function CreateFireModelRequestFromJSON(json: any): CreateFireModelRequest {
    return CreateFireModelRequestFromJSONTyped(json, false);
}

export function CreateFireModelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFireModelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'model': InitModelFromJSON(json['model']),
    };
}

export function CreateFireModelRequestToJSON(value?: CreateFireModelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'model': InitModelToJSON(value.model),
    };
}


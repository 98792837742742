/* tslint:disable */
/* eslint-disable */
/**
 * Device.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 * 
 * @export
 * @interface CreateDevice200Response
 */
export interface CreateDevice200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateDevice200Response
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDevice200Response
     */
    deviceToken: string;
}

/**
 * Check if a given object implements the CreateDevice200Response interface.
 */
export function instanceOfCreateDevice200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deviceId" in value;
    isInstance = isInstance && "deviceToken" in value;

    return isInstance;
}

export function CreateDevice200ResponseFromJSON(json: any): CreateDevice200Response {
    return CreateDevice200ResponseFromJSONTyped(json, false);
}

export function CreateDevice200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDevice200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['device_id'],
        'deviceToken': json['device_token'],
    };
}

export function CreateDevice200ResponseToJSON(value?: CreateDevice200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'device_token': value.deviceToken,
    };
}


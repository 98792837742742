/* tslint:disable */
/* eslint-disable */
/**
 * User Administrator.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { UserRole } from './UserRole';
import {
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 *
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequest
     */
    newFullName?: string;
    /**
     *
     * @type {UserRole}
     * @memberof UpdateUserRequest
     */
    newUserRole?: UserRole;
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequest
     */
    newUserUsername?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateUserRequest
     */
    userId: string;
}

/**
 * Check if a given object implements the UpdateUserRequest interface.
 */
export function instanceOfUpdateUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
    return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'newFullName': !exists(json, 'new_full_name') ? undefined : json['new_full_name'],
        'newUserRole': !exists(json, 'new_user_role') ? undefined : UserRoleFromJSON(json['new_user_role']),
        'newUserUsername': !exists(json, 'new_user_username') ? undefined : json['new_user_username'],
        'userId': json['user_id'],
    };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'new_full_name': value.newFullName,
        'new_user_role': UserRoleToJSON(value.newUserRole),
        'new_user_username': value.newUserUsername,
        'user_id': value.userId,
    };
}


import React from 'react';
import "./TableLine.css";
import Button from "../Button/Button";
import INFO_ICONS from "../../constants/infoIcons";

interface ITableLineProps {
    data: any
    isHeader?: boolean;
    columns?: string;
    h3?: boolean;
    onClick?: Function;
    asText?: boolean
}

const TableLine = (props: ITableLineProps) => {
  const styles = {
    gridTemplateColumns: props.columns ? props.columns : `repeat(${props.data.length}, 1fr)`,
    cursor: props.onClick ? "pointer" : "auto"
  };

  return (
    <div className={"table__line" + (props.isHeader ? " is-header" : "")} style={styles} onClick={(event) => {
      event.stopPropagation();
      if (props.onClick)
        props.onClick();
      else
        return false;
    }}>
      {props.data.map((el: any, i: React.Key) => {
        let content = el;

                if (el && el.icon) {
                    content = <>
                        <img src={INFO_ICONS[el.icon + (el.isDisabled ? "_disabled" : "")]} alt={"icon"} />
                        <span>{el.value}</span>
                    </>
                }

                let value = content.value ?? content;

                return <div key={i}>
                    {el.onClick && !el.asText ? <Button disabled={el.isDisabled ?? false}
                                                        value={el.value || content}
                                                        className={el.className} onClick={el.onClick}/> :
                        el.htmlValue ? <div className={el.className ?? ""} dangerouslySetInnerHTML={{__html: el.htmlValue}} /> :
                        props.isHeader ? <p className={el.className}>{value}{el.postfix ?? ""}</p> :
                            props.h3 ? <h3 className={el.className}>{value}{el.postfix ?? ""}</h3> : <h2 className={el.className} onClick={(event) => {
                                if (el.onClick) {
                                    event.stopPropagation();
                                    el.onClick();
                                }
                            }}>{value}{el.postfix ?? ""}</h2>}
                </div>
            })}
        </div>
    );
};

export default TableLine;

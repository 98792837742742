import React, {useState} from 'react';
import MinMaxSlider from "../MinMaxSlider/MinMaxSlider";
import "./MinMaxForm.css";
import Button from "../Button/Button";
import INFO_ICONS from "../../constants/infoIcons";
import {useScale} from "../../state/hooks/useScale";
import {getStopFire, secToTime, setStopFire} from "../../constants/utilFunctions";

interface IMinMaxFormProps {
    onSave: Function;
    onClose: Function;
}

const MinMaxForm = (props: IMinMaxFormProps) => {
    const [filters, setFilters] = useState<any>(getStopFire("filters"));
    const scale = useScale(1050, 1200);

    const getFilters = () => {
        let data: any = [];

        for (let key in filters) {
          if (key.match(/startTime/)) {
            continue;
          }

          let el = filters[key];
          data.push(
            <MinMaxSlider key={key} min={el.minmax[0]} max={el.minmax[1]}
                          icon={el.icon} step={el.step}
                          values={el.values}
                          onChange={(values: number[]) => {
                            setFilters({
                              ...filters,
                              [key]: {
                                ...filters[key],
                                values
                              }
                            })
                          }}
                          label={(val: number) => {
                            if (key === "fullTime") {
                              return secToTime(val);
                            }
                            // if (key === "oxygen_left") {
                            //     return val + "%";
                            // }

                            return val + "";
                          }}
                />);
        }

        return data;
    }

    return (
        <div className={"shadow"}>
            <div className="form-component minmax-form" style={{
                transform: `translateY(-50%) scale(${scale})`
            }}>
                <div className={"minmax-slider-container"}>
                    <img src={INFO_ICONS["calendar"]} alt={"icon"}/>
                    <input type="date"
                           value={filters.start_time_from}
                           className={"form-input"}
                           onChange={({target}: any) => {
                               setFilters({
                                   ...filters,
                                   startTimeFrom: target.value
                               });
                           }}
                    />
                    <input type="date"
                           value={filters.start_time_to}
                           className={"form-input"}
                           onChange={({target}: any) => {
                               setFilters({
                                   ...filters,
                                 startTimeTo: target.value
                               });
                           }}
                    />
                </div>

                {getFilters()}

                <Button value={"Сохранить"} onClick={() => {
                    setStopFire("filters", filters);
                    props.onSave(filters)
                }}/>
                <Button value={"Отмена"} onClick={() => props.onClose()}/>
            </div>
        </div>
    );
};

export default MinMaxForm;

import {getStopFire, resetTokenRequest} from "../constants/utilFunctions";

const getHeaders = () => {
  let token = getStopFire("userdata");
  token = token.accessToken ?? "";

  return new Headers({
    "Content-Type": "application/json",
    "user_token": token
  });
}

export const getData = async (url: string, callback?: Function) => {
    return buildRequest(url, "GET", null, callback);
}

export const buildRequest = async (url: string, method: string, data: any, callback?: Function) => {
  let init = {
    method: method,
    headers: getHeaders(),
  };

  if (data) {
    // @ts-ignore
    init["body"] = JSON.stringify(data);
  }

  const API_URL = localStorage.getItem('stopfireurl');
  const request = await fetch(`${API_URL}/${url}`, init).then(async res => {
      if (res.status === 401) {
        await resetTokenRequest(true);
      }
      return res.json();
    }
  ).catch(console.error);

  if (callback)
    callback(await request);

  return request;
}

import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import TableLine from "../../components/TableLine/TableLine";
import "./EndScreen.css";
import {useDispatch, useSelector} from "react-redux";
import {msToTime} from "../../constants/utilFunctions";
import Button from "../../components/Button/Button";
import {Link, useHistory} from "react-router-dom";
import {bindActionCreators} from "redux";
import {actionCreators} from '../../state/actions';
import {DEFAULT_END_DATA} from "../../constants/modelParamsConstants";
import {PlayerStatistics} from "../../api/player_statistics";

const EndScreen = () => {
    const [data, setData] = useState<PlayerStatistics>(DEFAULT_END_DATA);
    const {currentSession, playerStatistics} = useSelector((state: any) => state);

    const history = useHistory();
    const dispatch = useDispatch();
    const {dropSession, getPlayerStatistics, setActiveTab, resetFireModelPlayer} = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        if (!currentSession.id) {
            history.push("/sessionList");
            return;
        }

        setActiveTab(2);
        getPlayerStatistics({
            limit: 1,
            idsFilter: currentSession.id
        });
        resetFireModelPlayer();
    }, []);

    useEffect(() => {
      if (playerStatistics.playerStatistics.length) {
        setData(playerStatistics.playerStatistics[0]);
      }
    }, [playerStatistics]);

    const getTableData = (part: string) => {
        if (part === "top") {
          let dateString = new Date(data.startTime).toLocaleDateString('ru-RU');
          let timeString = new Date(data.startTime).toLocaleTimeString('ru-RU');

          return [
            data.sessionId.slice(0, 5),
            data.sessionName,
            data.userData.username,
            {
              icon: "calendar",
              value: `${dateString} ${timeString}`
            },
            {
              icon: "time",
              value: msToTime(data.fullTime / 1000000)
            }
          ];
        }

      let oxygenValue = "0";
      if (data.userData.oxygenLeft > 0)
        oxygenValue = (data.userData.oxygenLeft / currentSession.config.startingPlayersOxygen * 100).toFixed(2);

      return [
        {icon: "oxygen", value: oxygenValue, postfix: "%"},
        {icon: "liquid", value: data.userData.waterSpent.toFixed(0)},
        {icon: "heartRate", value: data.userData.heartRate},
        {icon: "size", value: data.sessionConfig.cellSize},
        {icon: "speed", value: data.sessionConfig.speedOfSpreading},
        {icon: "timeout", value: data.sessionConfig.whenSecondPhaseStartsSeconds},
        {icon: "hold", value: data.sessionConfig.cellWaterToEmpty},
        {icon: "stop", value: data.sessionConfig.cellWaterToSmolder}
      ];
    }

    const columns = "0.5fr 0.5fr 1fr 1fr 180px";

    return (
        <main className={"end-screen page"}>
            <Sidebar/>

          <main>
            <h1>Симуляция завершена</h1>

            {data.sessionId && <div className="table">
              <TableLine data={['id сессии', 'название сессии', "имя", "дата проведения", "время"]}
                         isHeader={true}
                         columns={columns}/>

              <div className="top"><TableLine data={getTableData("top")} columns={columns}/></div>
              <div className="bottom"><TableLine data={getTableData("bottom")}/></div>
            </div>}

            <Link to={"/statistics"}><Button value={"Закрыть"} onClick={() => dropSession()}/></Link>
          </main>
        </main>
    );
};

export default EndScreen;

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {Sidebar} from "../../components/Sidebar/Sidebar";
import Button from "../../components/Button/Button";
import FilterData from "../../components/FilterData/FilterData";
import TableLine from "../../components/TableLine/TableLine";
import {Pagination} from "../../components/Pagination/Pagination";
import Alert from "../../components/Alert/Alert";
import {actionCreators} from "../../state/actions";
import SessionForm from "../../components/SessionForm/SessionForm";
import {useHistory} from "react-router-dom";
import {copyToClipboard, setStopFire} from "../../constants/utilFunctions";
import {MODEL_DEFAULT_SETTINGS} from "../../constants/modelParamsConstants";
import {Loader} from "../../components/Loader/Loader";
import {FindSessions200Response, Session} from "../../api/session";

const SessionList = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [toDelete, setToDelete] = useState<Session | undefined>(undefined);
  const [isEdit, setIsEdit] = useState<Session | undefined>(undefined);
  const [data, setData] = useState<FindSessions200Response>({
    maxPages: 0,
    sessions: []
  });
  const [loading, setLoading] = useState(true);

  const {sessionList} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const {getSessionList, deleteSession, selectSession, setActiveTab} = bindActionCreators(actionCreators, dispatch);
  const history = useHistory();

  useEffect(() => {
    setActiveTab(2);
  }, []);

  useEffect(() => {
    setData(sessionList);
  }, [sessionList]);

  const filteredData = () => {
    const getStatusField = (el: Session) => {
      if (el.state === "Failed") {
        return {
          className: "flex",
          htmlValue: "<div class='dot red'></div><p>ПРОБЛЕМА С СЕССИЕЙ</p>"
        }
      }
      if (el.state === "Start") {
        return {
          className: "flex",
          htmlValue: "<div class='dot'></div><p>СЕССИЯ ЗАПУЩЕНА</p>"
        }
      }
      return "";
    }

    return data.sessions.map((el: Session) => {
      const {id, name, usersWithDevices, moderators, timeCreated} = el;
      if (usersWithDevices && moderators) {
        return [
          {
            asText: true,
            value: id.slice(0, 5),
            onClick: () => {
              copyToClipboard(id);
            }
          },
          name ?? "",
          moderators.length ? moderators[0].fullName : "–",
          new Date(timeCreated ?? "").toLocaleDateString(),
          getStatusField(el),
          {
            icon: "remove",
            className: "icon-13",
            isDisabled: el.state === "Start",
            onClick: () => {
              setToDelete(el);
            }
          },
          {
            icon: "edit",
            className: "icon-13",
            isDisabled: el.state === "Failed",
            onClick: () => {
              setIsEdit(el);
            }
          },
          {
            icon: "right",
            className: "icon-13",
            isDisabled: el.state === "Failed",
            onClick: async () => {
              new Promise((resolve) => {
                resolve(selectSession(el));
              }).then(() => {
                history.push("/simulation");
              });
            }
          }
        ]
          ;
      }

      return [];
    });
  }

  const columns = "1fr 2fr 2fr 2fr 2fr 60px 60px 60px";

  return <main className={"sessions page"}>
    <Sidebar/>
    <main>
      <header>
        <Button value={"Создать сессию"} onClick={() => {
          setIsAdd(true);
        }}/>

        <FilterData filterField={"nameFilter"} filterFunction={getSessionList} placeHolder={"Искать сессию"}/>
      </header>
      <div className="table">
        <TableLine data={['Id', 'Название сессии', 'Автор', 'Дата создания', '', '']} isHeader={true}
                   columns={columns}/>
        <div className="table__container">
          {filteredData().map((el: any, i: number) => <TableLine data={el} key={i} columns={columns}/>)}
        </div>
      </div>

      {loading && <Loader/>}

      <Pagination max_pages={sessionList.maxPages}
                  onChange={(limit: number, page: number) => {
                    new Promise((resolve) => {
                      setLoading(true);
                      resolve(getSessionList({limit, page}))
                    }).then(() => {
                      setLoading(false);
                    });
                  }}/>
    </main>

    {isAdd && <SessionForm onFinish={() => {
      setIsAdd(false);
      getSessionList();
      setStopFire("flame", MODEL_DEFAULT_SETTINGS);
    }}/>}

    {isEdit && <SessionForm session={isEdit} onFinish={() => {
      setIsEdit(undefined);
      getSessionList();
      setStopFire("flame", MODEL_DEFAULT_SETTINGS);
    }}/>}

    {toDelete && <Alert title={`Вы уверены, что хотите удалить сессию?`}
                        okText={"Подтвердить"} onOk={() => {
      new Promise(resolve => {
        resolve(deleteSession({
          id: toDelete.id
        }));
      }).then(() => {
        setToDelete(undefined);
        getSessionList();
      });
    }} cancelText={"Отмена"} onCancel={() => {
      setToDelete(undefined);
    }} maxWidth={376}/>}
  </main>
}

export default SessionList;

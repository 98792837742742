/* tslint:disable */
/* eslint-disable */
/**
 * Players Statistics.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Config } from './Config';
import {
    ConfigFromJSON,
    ConfigFromJSONTyped,
    ConfigToJSON,
} from './Config';
import type { Player } from './Player';
import {
    PlayerFromJSON,
    PlayerFromJSONTyped,
    PlayerToJSON,
} from './Player';

/**
 * 
 * @export
 * @interface PlayerStatistics
 */
export interface PlayerStatistics {
    /**
     * 
     * @type {string}
     * @memberof PlayerStatistics
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerStatistics
     */
    sessionName: string;
    /**
     * 
     * @type {Config}
     * @memberof PlayerStatistics
     */
    sessionConfig: Config;
    /**
     * 
     * @type {Date}
     * @memberof PlayerStatistics
     */
    startTime: Date;
    /**
     * 
     * @type {number}
     * @memberof PlayerStatistics
     */
    fullTime: number;
    /**
     * 
     * @type {Date}
     * @memberof PlayerStatistics
     */
    endTime: Date;
    /**
     * 
     * @type {Player}
     * @memberof PlayerStatistics
     */
    userData: Player;
}

/**
 * Check if a given object implements the PlayerStatistics interface.
 */
export function instanceOfPlayerStatistics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "sessionName" in value;
    isInstance = isInstance && "sessionConfig" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "fullTime" in value;
    isInstance = isInstance && "endTime" in value;
    isInstance = isInstance && "userData" in value;

    return isInstance;
}

export function PlayerStatisticsFromJSON(json: any): PlayerStatistics {
    return PlayerStatisticsFromJSONTyped(json, false);
}

export function PlayerStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['session_id'],
        'sessionName': json['session_name'],
        'sessionConfig': ConfigFromJSON(json['session_config']),
        'startTime': (new Date(json['start_time'])),
        'fullTime': json['full_time'],
        'endTime': (new Date(json['end_time'])),
        'userData': PlayerFromJSON(json['user_data']),
    };
}

export function PlayerStatisticsToJSON(value?: PlayerStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_id': value.sessionId,
        'session_name': value.sessionName,
        'session_config': ConfigToJSON(value.sessionConfig),
        'start_time': (value.startTime.toISOString()),
        'full_time': value.fullTime,
        'end_time': (value.endTime.toISOString()),
        'user_data': PlayerToJSON(value.userData),
    };
}


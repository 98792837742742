import React, {useState} from 'react';
import Button from "../Button/Button";

interface IFilterDataProps {
  placeHolder?: string;
  filterFunction: Function;
  filterField: string;
}

const FilterData = (props: IFilterDataProps) => {
  const [filterString, setFilterString] = useState("");

  const filterData = (empty = false) => {
    const filterObject = empty || !filterString.length ? {} : {
      [props.filterField]: filterString
    }
    props.filterFunction(filterObject);
  };

  return (
    <div className="filter">
      <input type="text" className={"form-input"} placeholder={props.placeHolder ?? "Искать пользователя"}
             onKeyUp={(e) => {
               if ((e.currentTarget.value === "" && filterString)) {
                 setFilterString("");
                 filterData(true);
                 return;
               }

               setFilterString(e.currentTarget.value);

               if (e.key === 'Enter') {
                 filterData();
               }
             }}/>
      <Button icon={"search"} className={"only-icon"} iconSizes={{w: 28, h: 28}}
              onClick={() => filterData()}/>
    </div>
  );
};

export default FilterData;

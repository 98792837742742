/* tslint:disable */
/* eslint-disable */
/**
 * Session.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { ActiveRole } from './ActiveRole';
import {
    ActiveRoleFromJSON,
    ActiveRoleFromJSONTyped,
    ActiveRoleToJSON,
} from './ActiveRole';
import type { Config } from './Config';
import {
    ConfigFromJSON,
    ConfigFromJSONTyped,
    ConfigToJSON,
} from './Config';
import type { Size } from './Size';
import {
    SizeFromJSON,
    SizeFromJSONTyped,
    SizeToJSON,
} from './Size';
import type { State } from './State';
import {
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './State';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { UserWithDevice } from './UserWithDevice';
import {
    UserWithDeviceFromJSON,
    UserWithDeviceFromJSONTyped,
    UserWithDeviceToJSON,
} from './UserWithDevice';

/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {ActiveRole}
     * @memberof Session
     */
    activeRole: ActiveRole;
    /**
     * 
     * @type {Config}
     * @memberof Session
     */
    config: Config;
    /**
     * 
     * @type {Array<UserWithDevice>}
     * @memberof Session
     */
    usersWithDevices: Array<UserWithDevice>;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    fullTime: number;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    id: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Session
     */
    moderators: Array<User>;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    name: string;
    /**
     * 
     * @type {Size}
     * @memberof Session
     */
    size: Size;
    /**
     * 
     * @type {State}
     * @memberof Session
     */
    state: State;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    timeCreated: Date;
}

/**
 * Check if a given object implements the Session interface.
 */
export function instanceOfSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "activeRole" in value;
    isInstance = isInstance && "config" in value;
    isInstance = isInstance && "usersWithDevices" in value;
    isInstance = isInstance && "fullTime" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "moderators" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "timeCreated" in value;

    return isInstance;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeRole': ActiveRoleFromJSON(json['active_role']),
        'config': ConfigFromJSON(json['config']),
        'usersWithDevices': ((json['users_with_devices'] as Array<any>).map(UserWithDeviceFromJSON)),
        'fullTime': json['full_time'],
        'id': json['id'],
        'moderators': ((json['moderators'] as Array<any>).map(UserFromJSON)),
        'name': json['name'],
        'size': SizeFromJSON(json['size']),
        'state': StateFromJSON(json['state']),
        'timeCreated': (new Date(json['time_created'])),
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_role': ActiveRoleToJSON(value.activeRole),
        'config': ConfigToJSON(value.config),
        'users_with_devices': ((value.usersWithDevices as Array<any>).map(UserWithDeviceToJSON)),
        'full_time': value.fullTime,
        'id': value.id,
        'moderators': ((value.moderators as Array<any>).map(UserToJSON)),
        'name': value.name,
        'size': SizeToJSON(value.size),
        'state': StateToJSON(value.state),
        'time_created': (value.timeCreated.toISOString()),
    };
}


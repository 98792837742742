/* tslint:disable */
/* eslint-disable */
/**
 * User Administrator.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface ChangeUserPasswordRequest
 */
export interface ChangeUserPasswordRequest {
    /**
     *
     * @type {string}
     * @memberof ChangeUserPasswordRequest
     */
    newPassword: string;
    /**
     *
     * @type {string}
     * @memberof ChangeUserPasswordRequest
     */
    oldPassword: string;
    /**
     *
     * @type {string}
     * @memberof ChangeUserPasswordRequest
     */
    username: string;
}

/**
 * Check if a given object implements the ChangeUserPasswordRequest interface.
 */
export function instanceOfChangeUserPasswordRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newPassword" in value;
    isInstance = isInstance && "oldPassword" in value;
    isInstance = isInstance && "username" in value;

    return isInstance;
}

export function ChangeUserPasswordRequestFromJSON(json: any): ChangeUserPasswordRequest {
    return ChangeUserPasswordRequestFromJSONTyped(json, false);
}

export function ChangeUserPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeUserPasswordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

      'newPassword': json['new_password'],
        'oldPassword': json['old_password'],
        'username': json['username'],
    };
}

export function ChangeUserPasswordRequestToJSON(value?: ChangeUserPasswordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

      'new_password': value.newPassword,
        'old_password': value.oldPassword,
        'username': value.username,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Fire Model Master Node.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { MaterialData } from './MaterialData';
import {
    MaterialDataFromJSON,
    MaterialDataFromJSONTyped,
    MaterialDataToJSON,
} from './MaterialData';
import type { Size } from './Size';
import {
    SizeFromJSON,
    SizeFromJSONTyped,
    SizeToJSON,
} from './Size';

/**
 * 
 * @export
 * @interface Matrix
 */
export interface Matrix {
    /**
     * 
     * @type {Array<Array<Array<MaterialData>>>}
     * @memberof Matrix
     */
    cellsMaterials: Array<Array<Array<MaterialData>>>;
    /**
     * 
     * @type {Size}
     * @memberof Matrix
     */
    size: Size;
}

/**
 * Check if a given object implements the Matrix interface.
 */
export function instanceOfMatrix(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cellsMaterials" in value;
    isInstance = isInstance && "size" in value;

    return isInstance;
}

export function MatrixFromJSON(json: any): Matrix {
    return MatrixFromJSONTyped(json, false);
}

export function MatrixFromJSONTyped(json: any, ignoreDiscriminator: boolean): Matrix {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cellsMaterials': json['CellsMaterials'],
        'size': SizeFromJSON(json['size']),
    };
}

export function MatrixToJSON(value?: Matrix | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CellsMaterials': value.cellsMaterials,
        'size': SizeToJSON(value.size),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Session.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ActiveRole = {
    Moderator: 'moderator',
    User: 'user'
} as const;
export type ActiveRole = typeof ActiveRole[keyof typeof ActiveRole];


export function ActiveRoleFromJSON(json: any): ActiveRole {
    return ActiveRoleFromJSONTyped(json, false);
}

export function ActiveRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveRole {
    return json as ActiveRole;
}

export function ActiveRoleToJSON(value?: ActiveRole | null): any {
    return value as any;
}


import React from 'react';
import Button from "../Button/Button";
import "./Alert.css";

interface IAlertProps {
    title: string;
    text?: string;
    onOk: Function;
    onCancel?: Function;
    okText?: string;
    cancelText?: string;
    maxWidth?: number;
    html?: string;
    className?: string;
    lowerCase?: boolean;
}

const Alert = (props: IAlertProps) => {
    let style:any = {};

    if (props.maxWidth) {
        style["width"] = props.maxWidth;
    }

    return (
        <div className={"shadow"}>
            <div className={"container " + (props.className || "")} style={style}>
                <div className="form-component">
                    <h2>{props.title}</h2>
                    {props.text && <p className={props.lowerCase ? "lowercase" : ""}>{props.text}</p>}
                    {props.html && <div className={props.lowerCase ? "lowercase" : ""}
                                        dangerouslySetInnerHTML={{__html: props.html}}/>}

                    <Button value={props.okText ?? "ОК"} onClick={() => props.onOk()}/>
                    {props.onCancel && <Button value={props.cancelText ?? "NO"}
                                               onClick={() => {
                                                   if (!props.onCancel)
                                                       return;

                                                   props.onCancel();
                                               }}/>}
                </div>
            </div>
        </div>
    );
};

export default Alert;

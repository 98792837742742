/* tslint:disable */
/* eslint-disable */
/**
 * Session.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { UserRole } from './UserRole';
import {
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userId: string;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    userRole: UserRole;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userRole" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['full_name'],
        'userId': json['user_id'],
        'userRole': UserRoleFromJSON(json['user_role']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full_name': value.fullName,
        'user_id': value.userId,
        'user_role': UserRoleToJSON(value.userRole),
    };
}


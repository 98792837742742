/* tslint:disable */
/* eslint-disable */
/**
 * Players Statistics.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Coordinate } from './Coordinate';
import {
    CoordinateFromJSON,
    CoordinateFromJSONTyped,
    CoordinateToJSON,
} from './Coordinate';

/**
 * 
 * @export
 * @interface CreatePlayer
 */
export interface CreatePlayer {
    /**
     * 
     * @type {string}
     * @memberof CreatePlayer
     */
    username: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePlayer
     */
    heartRate: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePlayer
     */
    oxygenLeft: number;
    /**
     * 
     * @type {Coordinate}
     * @memberof CreatePlayer
     */
    position: Coordinate;
    /**
     * 
     * @type {number}
     * @memberof CreatePlayer
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePlayer
     */
    waterSpent: number;
    /**
     * 
     * @type {Date}
     * @memberof CreatePlayer
     */
    startTime: Date;
    /**
     * 
     * @type {number}
     * @memberof CreatePlayer
     */
    fullTime: number;
    /**
     * 
     * @type {Date}
     * @memberof CreatePlayer
     */
    endTime: Date;
}

/**
 * Check if a given object implements the CreatePlayer interface.
 */
export function instanceOfCreatePlayer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "heartRate" in value;
    isInstance = isInstance && "oxygenLeft" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "waterSpent" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "fullTime" in value;
    isInstance = isInstance && "endTime" in value;

    return isInstance;
}

export function CreatePlayerFromJSON(json: any): CreatePlayer {
    return CreatePlayerFromJSONTyped(json, false);
}

export function CreatePlayerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePlayer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'heartRate': json['heart_rate'],
        'oxygenLeft': json['oxygen_left'],
        'position': CoordinateFromJSON(json['position']),
        'userId': json['user_id'],
        'waterSpent': json['water_spent'],
        'startTime': (new Date(json['start_time'])),
        'fullTime': json['full_time'],
        'endTime': (new Date(json['end_time'])),
    };
}

export function CreatePlayerToJSON(value?: CreatePlayer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'heart_rate': value.heartRate,
        'oxygen_left': value.oxygenLeft,
        'position': CoordinateToJSON(value.position),
        'user_id': value.userId,
        'water_spent': value.waterSpent,
        'start_time': (value.startTime.toISOString()),
        'full_time': value.fullTime,
        'end_time': (value.endTime.toISOString()),
    };
}


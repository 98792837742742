import React, {useEffect, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import Button from "../../components/Button/Button";
import "./Statistics.css";
import TableLine from "../../components/TableLine/TableLine";
import Checkbox from "../../components/Checkbox/Checkbox";
import MinMaxForm from "../../components/MinMaxForm/MinMaxForm";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from '../../state/actions';
import {capitalize, getStopFire, msToTime} from "../../constants/utilFunctions";
import {Pagination} from "../../components/Pagination/Pagination";
import FilterData from '../../components/FilterData/FilterData';
import {Loader} from "../../components/Loader/Loader";
import {PlayerStatistics} from "../../api/player_statistics";

const allFalse = (obj: any) => {
  for (let key in obj) {
    if (obj[key])
      return false;
  }
  return true;
}

const setupStatsData = (data: PlayerStatistics) => {
  let oxygen = data.userData.oxygenLeft / data.sessionConfig.startingPlayersOxygen;
  let percents = (oxygen * 100).toFixed(2);
  let waterSpent = data.userData.waterSpent.toFixed(0);
  let heartRate = data.userData.heartRate.toFixed(0);
  let datetime = new Date(data.startTime);
  let date = datetime.toLocaleDateString('ru-RU');
  let time = datetime.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'});

  return [
    {icon: null, value: data.sessionId.slice(0, 5)},
    {icon: null, value: data.sessionName || "session name"},
    {icon: null, value: data.userData.username},
    {icon: "calendar", value: `${date} ${time}`},
    {icon: "time", value: msToTime((data.fullTime / 1000000) || 0)},
    {icon: "oxygen", value: percents + "%"},
    {icon: "liquid", value: waterSpent},
    {icon: "heartRate", value: heartRate},
    {icon: "size", value: data.sessionConfig.cellSize},
    {icon: "speed", value: data.sessionConfig.speedOfSpreading},
    {icon: "timeout", value: data.sessionConfig.whenSecondPhaseStartsSeconds},
    {icon: "hold", value: data.sessionConfig.cellWaterToEmpty},
    {icon: "stop", value: data.sessionConfig.cellWaterToSmolder}
  ];
}

let defaultData: any = [];

const Statistics = () => {
  const [filters, setFilters] = useState({
    time: false,
    oxygen: false,
    liquid: false,
    heart_rate: false,
    size: false,
    speed: false,
    timeout: false,
    hold: false,
    stop: false
  });
  const [renderData, setRenderData] = useState([]);
  const [isShowMinMax, setIsShowMinMax] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const {getPlayerStatistics, setActiveTab} = bindActionCreators(actionCreators, dispatch);

  const {playerStatistics} = useSelector((state: any) => state);

  const toggle = (key: string) => {
    // @ts-ignore
    setFilters({...filters, [key]: !filters[key]});
  }

  const filterCheckboxes = () => {
    const selectAll = allFalse(filters);

    let dataLeft = defaultData.map((el: any) => {
      let res = [];
      for (let item of el) {
        // @ts-ignore
        if (!item.icon || (item.icon === "calendar") || selectAll || (item.icon && filters[item.icon]))
          res.push(item)
      }

      return res;
    });

    setRenderData(dataLeft);
  }

  useEffect(() => {
    setActiveTab(3);
    getPlayerStatistics();
  }, []);

  useEffect(() => {
    let res: any = [];

    for (let el of playerStatistics.playerStatistics) {
      res.push(setupStatsData(el));
    }

    defaultData = res;
    setRenderData(res);
  }, [playerStatistics]);

  useEffect(filterCheckboxes, [filters]);

  const getStatisticsRequest = (params: any) => {
    new Promise((resolve) => {
      setLoading(true);
      resolve(getPlayerStatistics(params));
    }).finally(() => {
      setLoading(false);
    });
  }

  const applyFilters = (filtered: any) => {
    let values: any = {};

    for (let key in filtered) {
      values[`range${capitalize(key)}Filter`] = filtered[key].values ?? filtered[key];
    }

    // Перевод в наносекунды
    values["rangeFullTimeFilter"] = [
      values["rangeFullTimeFilter"][0] * 1000000000,
      values["rangeFullTimeFilter"][1] * 1000000000,
    ];

    // Переводим в ISO-формат
    values["rangeStartTimeToFilter"] += "T23:59:05Z";
    values["rangeStartTimeFromFilter"] += "T01:01:05Z";

    getStatisticsRequest({
      ...values,
      limit: getStopFire("paginationLimit"),
    });

    setIsShowMinMax(false);
  }

  let columns = "60px 110px 150px 130px repeat(9, 1fr)";

  return (
    <main className={"statistics page"}>
      <Sidebar/>
      <main>
        <header>
          <h1>Статистика</h1>

          <div className="filtering">
            <Button icon={"filter"} className={"only-icon"} onClick={() => {
              setIsShowMinMax(true);
            }}/>
            <FilterData filterField={"sessionNameFilter"} filterFunction={getPlayerStatistics}
                        placeHolder={"Название сессии"}/>
          </div>
        </header>
        <div className="content">
          <div className="table">
            <div className="table__container">
              {
                renderData.map((el: any, i: any) => {
                  if (el[5] && parseFloat(el[5].value) < 0)
                    el[5].value = "0%";
                  return <TableLine columns={columns} data={el} key={i} h3={true}/>
                })
              }
            </div>
          </div>

          {loading && <Loader/>}

          <div className="checkboxes">
            <Checkbox label={"Время"} icon={"time"} onCheck={() => toggle("time")}/>
            <Checkbox label={"кислород"} icon={"oxygen"} onCheck={() => toggle("oxygen")}/>
            <Checkbox label={"туш. жидк."} icon={"liquid"} onCheck={() => toggle("liquid")}/>
            <Checkbox label={"средний пульс"} icon={"heartRate"} onCheck={() => toggle("heartRate")}/>
            <Checkbox label={"скорость распр."} icon={"speed"} onCheck={() => toggle("speed")}/>
            <Checkbox label={"размер ячейки"} icon={"size"} onCheck={() => toggle("size")}/>
            <Checkbox label={"вторая фаза"} icon={"timeout"} onCheck={() => toggle("timeout")}/>
            <Checkbox label={"прекращение распр."} icon={"hold"} onCheck={() => toggle("hold")}/>
            <Checkbox label={"прекращение горения"} icon={"stop"} onCheck={() => toggle("stop")}/>
          </div>
        </div>

        <Pagination max_pages={playerStatistics.maxPages}
                    onChange={(limit: number, page: number) => {
                      getStatisticsRequest({limit, page});
                    }}/>
      </main>
      {isShowMinMax && <MinMaxForm onSave={applyFilters} onClose={() => setIsShowMinMax(false)}/>}
    </main>
  );
};

export default Statistics;

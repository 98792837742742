/* tslint:disable */
/* eslint-disable */
/**
 * User Administrator.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 *
 * @export
 * @interface FindUsers200Response
 */
export interface FindUsers200Response {
    /**
     *
     * @type {number}
     * @memberof FindUsers200Response
     */
    maxPages: number;
    /**
     *
     * @type {Array<User>}
     * @memberof FindUsers200Response
     */
    users: Array<User>;
}

/**
 * Check if a given object implements the FindUsers200Response interface.
 */
export function instanceOfFindUsers200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "maxPages" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function FindUsers200ResponseFromJSON(json: any): FindUsers200Response {
    return FindUsers200ResponseFromJSONTyped(json, false);
}

export function FindUsers200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindUsers200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'maxPages': json['max_pages'],
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
    };
}

export function FindUsers200ResponseToJSON(value?: FindUsers200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'max_pages': value.maxPages,
        'users': ((value.users as Array<any>).map(UserToJSON)),
    };
}


import React from 'react';
import INFO_ICONS from "../../constants/infoIcons";
import {Slider} from "@mui/material";
import "./MinMaxSlider.css";

interface IMinMaxSlider {
    icon: string;
    min: number;
    max: number;
    step: number;
    values: number[],
    onChange?: Function
    label: (value: number) => string;
}

const MinMaxSlider = (props: IMinMaxSlider) => {
    const [value, setValue] = React.useState<number[]>([props.values[0], props.values[1]]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);

        if (props.onChange)
            props.onChange(value);
    };

    return (
        <div className={"minmax-slider-container"}>
            <img src={INFO_ICONS[props.icon]} alt="icon"/>
            <Slider
                min={props.min}
                max={props.max}
                step={props.step}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                valueLabelFormat={props.label}
            />
        </div>
    );
};

export default MinMaxSlider;

import React from 'react';
import Button from "../Button/Button";
import {msToTime} from "../../constants/utilFunctions";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../state/actions/index";

const Timer = () => {
    const {currentSession, fireModelPlayer} = useSelector((state: any) => state);

    const dispatch = useDispatch();
    const {changeSessionState} = bindActionCreators(actionCreators, dispatch);

    let theTime = fireModelPlayer ? msToTime(fireModelPlayer.fullTime / 1000000) : "00:00";

    return (
        <div className="timer">
            <Button icon={currentSession.state === "Start" ? "pause" : "resume"} className={"only-icon"} onClick={() => {
                if (currentSession.state !== "Start") {
                    // dispatch resume
                    changeSessionState({
                      id: currentSession.id,
                      newState: "Start"
                    });
                } else {
                    // dispatch reset
                    changeSessionState({
                      id: currentSession.id,
                      newState: "Pause"
                    });
                }
            }}/>

            <div className="time">
                <p>Время</p>
                {currentSession.state === "Start" ? <h1>{theTime}</h1> : <h1>--:--</h1>}
            </div>
        </div>
    );
};

export default Timer;

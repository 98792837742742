import React, {useEffect, useState} from 'react';
import Button from "../Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from '../../state/actions';
import SessionSettings from "../../layouts/SessionSettings/SessionSettings";
import {SessionUserList} from "../../layouts/UserList/SessionUserList";
import {SessionDeviceList} from "../../layouts/DeviceList/SessionDeviceList";
import Alert from "../Alert/Alert";
import {getInputtedFlameConfig} from "../../constants/utilFunctions";
import "./SessionForm.css";

interface ISessionForm {
  onFinish: Function;
  session?: any;
}

const defaultData: any = {
  name: "",
  usersWithDevices: [
    {
      user: {userId: ""},
      device: {deviceId: ""}
    }
  ],
};

const defaultEditData: any = {
  id: "",
  name: "",
  usersWithDevices: [
    {
      user: {userId: ""},
      device: {deviceId: ""}
    }
  ],
};

const SessionForm = (props: ISessionForm) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState("");
  const [editData, setEditData] = useState(defaultEditData);

  useEffect(() => {
    if (props.session && props.session.usersWithDevices) {
      const newValues = {
        id: props.session.id,
        name: props.session.name,
        usersWithDevices: props.session.usersWithDevices,
        size: props.session.size,
        config: props.session.config
      };

      setData({
        ...newValues
      });
    }
  }, []);

  useEffect(() => {
    if (!props.session || !props.session.usersWithDevices[0].user || !data.usersWithDevices[0].user.userId)
      return;

    let valueToUpdate: any = {};
    if (data.name !== props.session.name) {
      valueToUpdate = {
        newName: data.name
      };
    }

    let editDataValues = props.session.usersWithDevices[0];
    let newDataValues = data.usersWithDevices[0];

    if (newDataValues.user.userId !== editDataValues.user.userId) {
      const currentEditData = editData.addUserWithDevicesIds;
      const currentDeviceID = currentEditData ? currentEditData[0].deviceId : "";
      valueToUpdate = {
        ...valueToUpdate,
        deleteUserWithDevicesIds: [{
          userId: editDataValues.user.userId,
          deviceId: editDataValues.device.deviceId || newDataValues.device.deviceId,
        }],
        addUserWithDevicesIds: [{
          userId: newDataValues.user.userId,
          deviceId: currentDeviceID || newDataValues.device.deviceId || editDataValues.device.deviceId,
        }]
      };
    }

    if (newDataValues.device.deviceId !== editDataValues.device.deviceId) {
      const currentUser = valueToUpdate.addUserWithDevicesIds;
      const currentUserID = currentUser ? currentUser[0].userId : "";

      valueToUpdate = {
        ...valueToUpdate,
        deleteUserWithDevicesIds: [{
          userId: editDataValues.user.userId || newDataValues.user.userId,
          deviceId: editDataValues.device.deviceId,
        }],
        addUserWithDevicesIds: [{
          userId: currentUserID || newDataValues.user.userId || editDataValues.user.userId,
          deviceId: newDataValues.device.deviceId,
        }]
      };
    }

    // Если появились поля для обновления, добавляем id сессии и готовим к запросу
    if (Object.keys(valueToUpdate).length) {
      setEditData({
        ...valueToUpdate,
        id: props.session.id,
      });
    }
  }, [data]);

  const dispatch = useDispatch();
  const {createSession, updateSession} = bindActionCreators(actionCreators, dispatch);
  const {authorization} = useSelector((state: any) => state);

  const returnStep = () => {
    switch (step) {
      case 0:
        return <div className="container">
          <h2>{props.session ? "Редактирование сессии" : "Создать сессию"}</h2>
          <div className="form-component">
            <input type="text" className={"form-input"} placeholder={"Название сессии"}
                   defaultValue={data.name}
                   onInput={e => setData({
                     ...data,
                     name: e.currentTarget.value
                   })}/>

            <Button value={"Сохранить"} onClick={() => {
              if (data.name) {
                setStep(1)
              } else {
                setError("Введите название сессии!");
              }
            }}/>
            <Button value={"отмена"} onClick={() => {
              props.onFinish()
            }}/>
          </div>
        </div>;
      case 1:
        return <SessionSettings usersWithDevices={data.usersWithDevices}
                                config={props.session ? props.session.config : null}
                                setUser={() => setStep(2)} setDevice={() => setStep(3)}
                                onClose={() => setStep(0)} onSave={() => {
          if (props.session) {
            new Promise((resolve) => {
              const {
                id,
                newName,
                addUserWithDevicesIds,
                deleteUserWithDevicesIds
              } = editData;

              resolve(updateSession({
                id,
                newName,
                addUserWithDevicesIds,
                deleteUserWithDevicesIds,
                config: getInputtedFlameConfig(),
                addModeratorIds: props.session.moderators.length ? [] : [authorization.userId]
              }));
            }).then(() => {
              props.onFinish();
            }).catch(() => {
              setError('Произошла ошибка');
            });
          } else {
            if (!data.usersWithDevices[0].user.userId) {
              setError("Выберите игрока!");
              return;
            }
            if (!data.usersWithDevices[0].device.deviceId) {
              setError("Выберите устройство!");
              return;
            }

            new Promise((resolve) => {
              resolve(createSession({
                name: data.name,
                config: getInputtedFlameConfig(),
                moderatorIds: [authorization.userId],
                userWithDevicesIds: [{
                  userId: data.usersWithDevices[0].user.userId,
                  deviceId: data.usersWithDevices[0].device.deviceId,
                }],
              }));
            }).then(() => {
              props.onFinish();
            })
              .catch(() => {
                setError("Подробности в консоли");
              });
          }
        }}/>;
      case 2:
        return <SessionUserList onClose={() => setStep(1)} setUser={(user: any) => {
          setData({
            ...data,
            usersWithDevices: [
              {
                ...data.usersWithDevices[0],
                user
              }
            ],
          });
          setStep(1);
        }}/>
      default:
        return <SessionDeviceList onClose={() => setStep(1)} setDevice={(device: any) => {
          setData({
            ...data,
            usersWithDevices: [
              {
                ...data.usersWithDevices[0],
                device
              }
            ],
          });
          setStep(1);
        }}/>
    }
  }

  // @ts-ignore
  window.hasChangedConfig = false;

  return (
    <div className={"shadow"}>
      {error ? <Alert className={"error"} title={"Произошла ошибка!"} html={error} onOk={() => {
        setError("")
      }}/> : returnStep()}
    </div>
  );
};

export default SessionForm;

import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";
import Auth from "./pages/Auth/Auth";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import {useDispatch, useSelector} from "react-redux";
import Statistics from "./pages/Statistics/Statistics";
import Simulation from "./pages/Simulation/Simulation";
import EndScreen from "./pages/EndScreen/EndScreen";
import {bindActionCreators} from "redux";
import {actionCreators} from './state/actions';
import Devices from "./pages/Devices/Devices";
import SessionList from "./pages/SessionList/SessionList";
import {getVersion} from "./state/actions/actionCreators";

function App() {
  let {authorization} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const {resetToken, getUsers, getDevices} = bindActionCreators(actionCreators, dispatch);
  const [version, setVersion] = useState("");
  let frontVersion = useRef();

  function authorizationCallback() {
    if (!authorization.accessToken) {
      return;
    }

    let loggedAt = new Date(authorization.logged_at);
    let expiration = authorization.access_expires_in / 3600;
    let timeNow = new Date();
    loggedAt.setHours(loggedAt.getHours() + expiration);

    if (timeNow > loggedAt) {
      console.log("Time to reset token", timeNow, loggedAt)
      resetToken();
    }
  }

  function getVersionCallback() {
    (async () => {
      const response = await getVersion();
      setVersion(response!);
    })();
  }

  useEffect(() => {
    (async () => {
      await fetch('./constants.json')
        .then(r => r.json())
        .then(res => {
          localStorage.setItem("stopfireurl", res.API_URL);
          frontVersion.current = res.FRONT_VERSION;
        }).then(() => {
          setTimeout(() => {
            authorizationCallback();
            getVersionCallback();
          }, 500);
        });
    })();
  }, []);

  const routesAfterAuth = () => {
    return <>
      <Route path={"/admin"} component={AdminPanel}/>
      <Route path={"/devices"} component={Devices}/>
      <Route path={"/sessionList"} component={SessionList}/>
      <Route path={"/simulation"} component={Simulation}/>
      <Route path={"/statistics"} component={Statistics}/>
      <Route path={"/end"} component={EndScreen}/>

      <Route exact path={"/"}>
        {authorization.role === "user" ? <Redirect to={"/statistics"}/> : <Redirect to={'/admin'}/>}
      </Route>
    </>;
  }

  return <>
    <div className={'version'}>
      <span>front: v{frontVersion.current}</span>
      <br/>
      <span>back: v{version}</span>
    </div>
    <Switch>
      {authorization.accessToken ? routesAfterAuth() : <Route exact path={"/"} component={Auth}/>}
      {!authorization.accessToken && <Redirect to={"/"}/>}
    </Switch>
  </>;
}

export default App;

import TableLine from "../../components/TableLine/TableLine";
import {Pagination} from "../../components/Pagination/Pagination";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../state/actions";
import {copyToClipboard} from "../../constants/utilFunctions";
import {Loader} from "../../components/Loader/Loader";
import {Device, FindDevices} from "../../api/device";

const DeviceList = (props: any) => {
    const [data, setData] = useState<FindDevices>({
        maxPages: 0,
        devices: []
    });
    const [loading, setLoading] = useState(true);

    const {devices} = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const {getDevices} = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        setData(devices);
    }, [devices]);

    const filteredData = () => {
        return data.devices.map((el: Device) => {
            const menu: any = [
                el.deviceName,
                el.deviceIp,
                {
                    icon: "copy",
                    className: "reverse icon",
                    value: el.deviceToken,
                    asText: true,
                    onClick: () => {
                        copyToClipboard(el.deviceToken);
                    }
                },
                {
                    icon: "remove",
                    className: "icon-13",
                    onClick: () => {
                        props.setToDelete(el)
                    }
                }
            ];

            return menu;
        });
    }

    const columns = "1fr 1fr 4fr 60px";

    return <>
        <div className="table">
            <TableLine data={['Имя устройства', 'IP', "API TOKEN", ""]} isHeader={true} columns={columns}/>
            <div className="table__container">
                {filteredData().map((el: any, i: number) => <TableLine data={el} key={i} columns={columns}/>)}
            </div>
        </div>

        {loading && <Loader/>}

        <Pagination max_pages={devices.maxPages}
                    onChange={(limit: number, page: number) => {
                        new Promise((resolve) => {
                            setLoading(true);
                            resolve(getDevices({limit, page}))
                        }).then(() => {
                            setLoading(false);
                        });
                    }}/>
    </>;
}

export default DeviceList;

import hold_big from "../images/icons/range/hold_big.svg";
import hold_small from "../images/icons/range/hold_small.svg";

import size_big from "../images/icons/range/size_big.svg";
import size_small from "../images/icons/range/size_small.svg";

import speed_big from "../images/icons/range/speed_big.svg";
import speed_small from "../images/icons/range/speed_small.svg";

import stop_big from "../images/icons/range/stop_big.svg";
import stop_small from "../images/icons/range/stop_small.svg";

import timeout_big from "../images/icons/range/timeout_big.svg";
import timeout_small from "../images/icons/range/timeout_small.svg";

const RANGE_ICONS:any = {
  cellWaterToEmpty: {
    big: hold_big,
    small: hold_small
  },
  cellSize: {
    big: size_big,
    small: size_small
  },
  speedOfSpreading: {
    big: speed_big,
    small: speed_small
  },
  cellWaterToSmolder: {
    big: stop_big,
    small: stop_small
  },
  whenSecondPhaseStartsSeconds: {
    big: timeout_big,
    small: timeout_small
  },
}

export default RANGE_ICONS;

/* tslint:disable */
/* eslint-disable */
/**
 * Fire Model Master Node.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Coordinate } from './Coordinate';
import {
    CoordinateFromJSON,
    CoordinateFromJSONTyped,
    CoordinateToJSON,
} from './Coordinate';

/**
 * 
 * @export
 * @interface CellCoordinateWithWater
 */
export interface CellCoordinateWithWater {
    /**
     * 
     * @type {Coordinate}
     * @memberof CellCoordinateWithWater
     */
    coordinate: Coordinate;
    /**
     * 
     * @type {number}
     * @memberof CellCoordinateWithWater
     */
    waterInCell: number;
}

/**
 * Check if a given object implements the CellCoordinateWithWater interface.
 */
export function instanceOfCellCoordinateWithWater(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "coordinate" in value;
    isInstance = isInstance && "waterInCell" in value;

    return isInstance;
}

export function CellCoordinateWithWaterFromJSON(json: any): CellCoordinateWithWater {
    return CellCoordinateWithWaterFromJSONTyped(json, false);
}

export function CellCoordinateWithWaterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CellCoordinateWithWater {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordinate': CoordinateFromJSON(json['coordinate']),
        'waterInCell': json['water_in_cell'],
    };
}

export function CellCoordinateWithWaterToJSON(value?: CellCoordinateWithWater | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coordinate': CoordinateToJSON(value.coordinate),
        'water_in_cell': value.waterInCell,
    };
}


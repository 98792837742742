import React from 'react';
import SidebarMenuItem from "../SidebarMenuItem/SIdebarMenuItem";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../state/actions";
import {useHistory} from "react-router-dom";

interface ISidebarMenuProps {
    callback?: Function;
}

const SidebarMenu = (props: ISidebarMenuProps) => {
    const {authorization} = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const {setActiveTab, logoutAction} = bindActionCreators(actionCreators, dispatch);

    const history = useHistory();

    const changePage = (link: string, tabId: number) => {
        if (props.callback) {
            // if async, use then(), else just call it
            if (props.callback.constructor.name === "AsyncFunction") {
                props.callback().then(() => {
                    setActiveTab(tabId);
                    history.push(link);
                });
                return;
            }

            props.callback();
        }
        setActiveTab(tabId);
        history.push(link);
    }

    let moderator_menu = [
        {
            id: 0,
            icon: "admin",
            link: "/admin",
            name: "Администрирование",
            submenu: []
        },
        {
            id: 1,
            icon: "devices",
            link: "/devices",
            name: "Устройства",
            submenu: []
        },
        {
            id: 2,
            icon: "simulation",
            link: "/sessionList",
            name: "Симуляция",
            submenu: []
        },
        {
            id: 3,
            icon: "statistics",
            link: "/statistics",
            name: "Статистика",
            submenu: []
        },
        {
            id: 4,
            icon: "logout",
            link: "/",
            name: "Выход",
            submenu: [],
            onClick: (event: MouseEvent) => {
                event.stopPropagation();
                logoutAction();
            }
        }
    ];

    let admin_menu = [
        {
            id: 2,
            icon: "admin",
            link: "/admin",
            name: "Администрирование",
            submenu: []
        },
        {
            id: 5,
            icon: "logout",
            link: "/",
            name: "Выход",
            submenu: [],
            onClick: (event: MouseEvent) => {
                event.stopPropagation();
                logoutAction();
            }
        }
    ];

    // Изначально грузим меню пользователя, а потом проверяем роль, и, если нужно, заменяем
    let menu:any = [
        {
            id: 2,
            icon: "statistics",
            link: "/statistics",
            name: "Статистика",
            submenu: []
        },
        {
            id: 5,
            icon: "logout",
            link: "/",
            name: "Выход",
            submenu: [],
            onClick: (event: MouseEvent) => {
                event.stopPropagation();
                logoutAction();
            }
        }
    ];

    if (authorization.role === "admin") {
        menu = admin_menu;
    } else if (authorization.role === "moderator") {
        menu = moderator_menu;
    }

    const getMenu = () => {
        return menu.map((el:any) => <SidebarMenuItem key={el.id} icon={el.icon} link={el.link}
                                               name={el.name} submenu={el.submenu} id={el.id}
                                               onClick={(event: MouseEvent) => {
                                                   el.onClick ? el.onClick(event) :
                                                       changePage(el.link, el.id)
                                               }}/>);
    }

    return (
        <div className={"sidebar__menu"} style={{width: "100%"}}>
            {getMenu()}
        </div>
    );
};

export default SidebarMenu;

/* tslint:disable */
/* eslint-disable */
/**
 * User Administrator.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 *
 * @export
 * @interface Login200Response
 */
export interface Login200Response {
    /**
     *
     * @type {number}
     * @memberof Login200Response
     */
    accessExpiresIn: number;
    /**
     *
     * @type {string}
     * @memberof Login200Response
     */
    accessToken: string;
    /**
     *
     * @type {string}
     * @memberof Login200Response
     */
    fullname: string;
    /**
     *
     * @type {number}
     * @memberof Login200Response
     */
    refreshExpiresIn: number;
    /**
     *
     * @type {string}
     * @memberof Login200Response
     */
    refreshToken: string;
    /**
     *
     * @type {string}
     * @memberof Login200Response
     */
    role: string;
    /**
     *
     * @type {string}
     * @memberof Login200Response
     */
    userId: string;
}

/**
 * Check if a given object implements the Login200Response interface.
 */
export function instanceOfLogin200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessExpiresIn" in value;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "fullname" in value;
    isInstance = isInstance && "refreshExpiresIn" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function Login200ResponseFromJSON(json: any): Login200Response {
    return Login200ResponseFromJSONTyped(json, false);
}

export function Login200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Login200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'accessExpiresIn': json['access_expires_in'],
        'accessToken': json['access_token'],
        'fullname': json['fullname'],
        'refreshExpiresIn': json['refresh_expires_in'],
        'refreshToken': json['refresh_token'],
        'role': json['role'],
        'userId': json['user_id'],
    };
}

export function Login200ResponseToJSON(value?: Login200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'access_expires_in': value.accessExpiresIn,
        'access_token': value.accessToken,
        'fullname': value.fullname,
        'refresh_expires_in': value.refreshExpiresIn,
        'refresh_token': value.refreshToken,
        'role': value.role,
        'user_id': value.userId,
    };
}


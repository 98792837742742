/* tslint:disable */
/* eslint-disable */
/**
 * Fire Model Master Node.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 * 
 * @export
 * @interface Config
 */
export interface Config {
    /**
     * 
     * @type {number}
     * @memberof Config
     */
    cellSize: number;
    /**
     * 
     * @type {number}
     * @memberof Config
     */
    cellWaterToEmpty: number;
    /**
     * 
     * @type {number}
     * @memberof Config
     */
    cellWaterToSmolder: number;
    /**
     * 
     * @type {number}
     * @memberof Config
     */
    speedOfSpreading: number;
    /**
     * 
     * @type {number}
     * @memberof Config
     */
    startingPlayersOxygen: number;
    /**
     * 
     * @type {number}
     * @memberof Config
     */
    whenSecondPhaseStartsSeconds: number;
}

/**
 * Check if a given object implements the Config interface.
 */
export function instanceOfConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cellSize" in value;
    isInstance = isInstance && "cellWaterToEmpty" in value;
    isInstance = isInstance && "cellWaterToSmolder" in value;
    isInstance = isInstance && "speedOfSpreading" in value;
    isInstance = isInstance && "startingPlayersOxygen" in value;
    isInstance = isInstance && "whenSecondPhaseStartsSeconds" in value;

    return isInstance;
}

export function ConfigFromJSON(json: any): Config {
    return ConfigFromJSONTyped(json, false);
}

export function ConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): Config {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cellSize': json['cell_size'],
        'cellWaterToEmpty': json['cell_water_to_empty'],
        'cellWaterToSmolder': json['cell_water_to_smolder'],
        'speedOfSpreading': json['speed_of_spreading'],
        'startingPlayersOxygen': json['starting_players_oxygen'],
        'whenSecondPhaseStartsSeconds': json['when_second_phase_starts_seconds'],
    };
}

export function ConfigToJSON(value?: Config | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cell_size': value.cellSize,
        'cell_water_to_empty': value.cellWaterToEmpty,
        'cell_water_to_smolder': value.cellWaterToSmolder,
        'speed_of_spreading': value.speedOfSpreading,
        'starting_players_oxygen': value.startingPlayersOxygen,
        'when_second_phase_starts_seconds': value.whenSecondPhaseStartsSeconds,
    };
}


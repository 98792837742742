import React, {useState} from 'react';
import Icon from "../Icon/Icon";
import "./SidebarMenuItem.css";
import {useSelector} from "react-redux";

interface IMenuItemProps {
    id?: number;
    link: string;
    name: string;
    icon: string;
    onClick: any;
    submenu: any;
    isSubmenu?: boolean;
}

const SidebarMenuItem = (props: IMenuItemProps) => {
    const [hover, setHover] = useState(false);
    const {active, isFull} = useSelector((state: any) => state.menu);
    const isActive = props.id === active;

    return (
        <div className={"sidebar__menu__item" + (isActive ? " active" : "")}
             onMouseMove={() => {
                 if (!hover)
                     setHover(true)}
             } onMouseLeave={() => setHover(false)}
             onClick={props.onClick}
        >
            <Icon icon={props.icon} hover={hover} selected={isActive || hover} cantHover={true}/>
            {props.isSubmenu ?
                <h3 className={!isFull ? "hide" : ""}>{props.name}</h3> :
                <h2 className={!isFull ? "hide" : ""}>{props.name}</h2>
            }
            <div className={"submenu" + (props.submenu.length ? "" : " dn")}>
                {props.submenu && props.submenu.map((el: IMenuItemProps) =>
                    <SidebarMenuItem key={el.id} link={el.link} name={el.name} icon={el.icon}
                                     onClick={el.onClick} submenu={el.submenu}
                                     isSubmenu={true} id={el.id}
                    />)}
            </div>
        </div>
    );
};

export default SidebarMenuItem;

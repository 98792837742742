/* tslint:disable */
/* eslint-disable */
/**
 * Session.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Coordinate } from './Coordinate';
import {
    CoordinateFromJSON,
    CoordinateFromJSONTyped,
    CoordinateToJSON,
} from './Coordinate';
import type { InitData } from './InitData';
import {
    InitDataFromJSON,
    InitDataFromJSONTyped,
    InitDataToJSON,
} from './InitData';
import type { Size } from './Size';
import {
    SizeFromJSON,
    SizeFromJSONTyped,
    SizeToJSON,
} from './Size';

/**
 * 
 * @export
 * @interface UpdateMap
 */
export interface UpdateMap {
    /**
     * 
     * @type {Array<Coordinate>}
     * @memberof UpdateMap
     */
    activeTemperatureCells: Array<Coordinate>;
    /**
     * 
     * @type {Array<Array<Array<InitData>>>}
     * @memberof UpdateMap
     */
    cellsMaterialValues: Array<Array<Array<InitData>>>;
    /**
     * 
     * @type {Size}
     * @memberof UpdateMap
     */
    size: Size;
}

/**
 * Check if a given object implements the UpdateMap interface.
 */
export function instanceOfUpdateMap(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "activeTemperatureCells" in value;
    isInstance = isInstance && "cellsMaterialValues" in value;
    isInstance = isInstance && "size" in value;

    return isInstance;
}

export function UpdateMapFromJSON(json: any): UpdateMap {
    return UpdateMapFromJSONTyped(json, false);
}

export function UpdateMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeTemperatureCells': ((json['active_temperature_cells'] as Array<any>).map(CoordinateFromJSON)),
        'cellsMaterialValues': json['cells_material_values'],
        'size': SizeFromJSON(json['size']),
    };
}

export function UpdateMapToJSON(value?: UpdateMap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_temperature_cells': ((value.activeTemperatureCells as Array<any>).map(CoordinateToJSON)),
        'cells_material_values': value.cellsMaterialValues,
        'size': SizeToJSON(value.size),
    };
}


import close from "../images/icons/in_game/close.svg";
import close_hover from "../images/icons/in_game/close_hover.svg";
import close_selected from "../images/icons/in_game/close_selected.svg";

import open_icon from "../images/icons/in_game/open.svg";
import open_hover from "../images/icons/in_game/open_hover.svg";
import open_selected from "../images/icons/in_game/open_selected.svg";

import admin_icon from "../images/icons/in_game/administration.svg";
import admin_hover from "../images/icons/in_game/administration_hover.svg";
import admin_selected from "../images/icons/in_game/administration_selected.svg";
import admin_disabled from "../images/icons/in_game/administration_disabled.svg";

import simulation_icon from "../images/icons/in_game/simulation.svg";
import simulation_hover from "../images/icons/in_game/simulation_hover.svg";
import simulation_selected from "../images/icons/in_game/simulation_selected.svg";
import simulation_disabled from "../images/icons/in_game/simulation_disabled.svg";

import statistics_icon from "../images/icons/in_game/statistics.svg";
import statistics_hover from "../images/icons/in_game/statistics_hover.svg";
import statistics_selected from "../images/icons/in_game/statistics_selected.svg";
import statistics_disabled from "../images/icons/in_game/statistics_disabled.svg";

import settings_icon from "../images/icons/in_game/settings.svg";
import settings_hover from "../images/icons/in_game/settings_hover.svg";
import settings_selected from "../images/icons/in_game/settings_selected.svg";
import settings_disabled from "../images/icons/in_game/settings_disabled.svg";

import flame_icon from "../images/icons/in_game/flame.svg";
import flame_hover from "../images/icons/in_game/flame_hover.svg";
import flame_selected from "../images/icons/in_game/flame_selected.svg";
import flame_disabled from "../images/icons/in_game/flame_disabled.svg";

import flat_icon from "../images/icons/in_game/flat.svg";
import flat_hover from "../images/icons/in_game/flat_hover.svg";
import flat_selected from "../images/icons/in_game/flat_selected.svg";
import flat_disabled from "../images/icons/in_game/flat_disabled.svg";

import threeD_icon from "../images/icons/in_game/threeD.svg";
import threeD_hover from "../images/icons/in_game/threeD_hover.svg";
import threeD_selected from "../images/icons/in_game/threeD_selected.svg";
import threeD_disabled from "../images/icons/in_game/threeD_disabled.svg";

import search from "../images/icons/in_game/search.svg";
import search_disabled from "../images/icons/in_game/search_disabled.svg";

import square from "../images/icons/in_game/square.svg";
import square_disabled from "../images/icons/in_game/square_disabled.svg";

import clear from "../images/icons/in_game/clear.svg";
import clear_disabled from "../images/icons/in_game/clear_disabled.svg";

import cube from "../images/icons/in_game/cube.svg";
import cube_disabled from "../images/icons/in_game/cube_disabled.svg";

import pause from "../images/icons/in_game/pause.svg";
import pause_disabled from "../images/icons/in_game/pause_disabled.svg";

import filter from "../images/icons/in_game/filter.svg";
import filter_disabled from "../images/icons/in_game/filter_disabled.svg";

import edit from "../images/icons/in_game/edit.svg";
import edit_disabled from "../images/icons/in_game/edit_disabled.svg";

import select from "../images/icons/in_game/select.svg";

import logout from "../images/icons/in_game/logout.svg";
import logout_hover from "../images/icons/in_game/logout_hover.svg";
import logout_selected from "../images/icons/in_game/logout_selected.svg";
import logout_disabled from "../images/icons/in_game/logout_disabled.svg";

import resume from "../images/icons/in_game/resume.svg";
import resume_disabled from "../images/icons/in_game/resume_disabled.svg";

import devices from "../images/icons/in_game/devices.svg";
import devices_hover from "../images/icons/in_game/devices_hover.svg";
import devices_selected from "../images/icons/in_game/devices_selected.svg";
import devices_disabled from "../images/icons/in_game/devices_disabled.svg";

import left from "../images/icons/in_game/left.svg";
import left_disabled from "../images/icons/in_game/left_disabled.svg";

import right from "../images/icons/in_game/right.svg";
import right_disabled from "../images/icons/in_game/right_disabled.svg";

import vr from "../images/icons/in_game/vr.svg";
import userpic from "../images/icons/in_game/userpic.svg";
import user_voice from "../images/icons/in_game/user_voice.svg";
import user from "../images/icons/in_game/user.svg";

const ICONS:any = {
    "close": {
        base: close,
        hover: close_hover,
        selected: close_selected
    },
    "open": {
        base: open_icon,
        hover: open_hover,
        selected: open_selected
    },
    "admin": {
        base: admin_icon,
        hover: admin_hover,
        selected: admin_selected,
        disabled: admin_disabled
    },
    "simulation": {
        base: simulation_icon,
        hover: simulation_hover,
        selected: simulation_selected,
        disabled: simulation_disabled
    },
    "statistics": {
        base: statistics_icon,
        hover: statistics_hover,
        selected: statistics_selected,
        disabled: statistics_disabled
    },
    "settings": {
        base: settings_icon,
        hover: settings_hover,
        selected: settings_selected,
        disabled: settings_disabled
    },
    "flame": {
        base: flame_icon,
        hover: flame_hover,
        selected: flame_selected,
        disabled: flame_disabled
    },
    "flat": {
        base: flat_icon,
        hover: flat_hover,
        selected: flat_selected,
        disabled: flat_disabled
    },
    "threeD": {
        base: threeD_icon,
        hover: threeD_hover,
        selected: threeD_selected,
        disabled: threeD_disabled
    },
    "search": {
        base: search,
        hover: search,
        selected: search,
        disabled: search_disabled
    },
    "square": {
        base: square,
        hover: square,
        selected: square,
        disabled: square_disabled
    },
    "clear": {
        base: clear,
        hover: clear,
        selected: clear,
        disabled: clear_disabled
    },
    "cube": {
        base: cube,
        hover: cube,
        selected: cube,
        disabled: cube_disabled
    },
    "pause": {
        base: pause,
        hover: pause,
        selected: pause,
        disabled: pause_disabled
    },
    "edit": {
        base: edit,
        hover: edit,
        selected: edit,
        disabled: edit_disabled
    },
    "filter": {
        base: filter,
        hover: filter,
        selected: filter,
        disabled: filter_disabled
    },
    "select": {
        base: select,
        hover: select,
        selected: select,
        disabled: select
    },
    "logout": {
        base: logout,
        hover: logout_hover,
        selected: logout_selected,
        disabled: logout_disabled
    },
    "resume": {
        base: resume,
        hover: resume,
        selected: resume,
        disabled: resume_disabled
    },
    "devices": {
        base: devices,
        hover: devices_hover,
        selected: devices_selected,
        disabled: devices_disabled
    },
    "left": {
        base: left,
        hover: left,
        selected: left,
        disabled: left_disabled
    },
    "right": {
        base: right,
        hover: right,
        selected: right,
        disabled: right_disabled
    },
    "vr": {
        base: vr,
        hover: vr,
        selected: vr,
    },
    "userpic": {
        base: userpic,
        hover: userpic,
        selected: userpic,
    },
    "user_voice": {
        base: user_voice,
        hover: user_voice,
        selected: user_voice,
    },
    "user": {
        base: user,
        hover: user,
        selected: user,
    },
}

export default ICONS;

/* tslint:disable */
/* eslint-disable */
/**
 * Players Statistics.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { PlayerStatistics } from './PlayerStatistics';
import {
    PlayerStatisticsFromJSON,
    PlayerStatisticsFromJSONTyped,
    PlayerStatisticsToJSON,
} from './PlayerStatistics';

/**
 * 
 * @export
 * @interface FindPlayersStatistic200Response
 */
export interface FindPlayersStatistic200Response {
    /**
     * 
     * @type {number}
     * @memberof FindPlayersStatistic200Response
     */
    maxPages: number;
    /**
     * 
     * @type {Array<PlayerStatistics>}
     * @memberof FindPlayersStatistic200Response
     */
    playerStatistics: Array<PlayerStatistics>;
}

/**
 * Check if a given object implements the FindPlayersStatistic200Response interface.
 */
export function instanceOfFindPlayersStatistic200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "maxPages" in value;
    isInstance = isInstance && "playerStatistics" in value;

    return isInstance;
}

export function FindPlayersStatistic200ResponseFromJSON(json: any): FindPlayersStatistic200Response {
    return FindPlayersStatistic200ResponseFromJSONTyped(json, false);
}

export function FindPlayersStatistic200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindPlayersStatistic200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxPages': json['max_pages'],
        'playerStatistics': ((json['player_statistics'] as Array<any>).map(PlayerStatisticsFromJSON)),
    };
}

export function FindPlayersStatistic200ResponseToJSON(value?: FindPlayersStatistic200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'max_pages': value.maxPages,
        'player_statistics': ((value.playerStatistics as Array<any>).map(PlayerStatisticsToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Device.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 * 
 * @export
 * @interface UpdateDeviceRequest
 */
export interface UpdateDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequest
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequest
     */
    deviceIp: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequest
     */
    deviceName: string;
}

/**
 * Check if a given object implements the UpdateDeviceRequest interface.
 */
export function instanceOfUpdateDeviceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deviceId" in value;
    isInstance = isInstance && "deviceIp" in value;
    isInstance = isInstance && "deviceName" in value;

    return isInstance;
}

export function UpdateDeviceRequestFromJSON(json: any): UpdateDeviceRequest {
    return UpdateDeviceRequestFromJSONTyped(json, false);
}

export function UpdateDeviceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDeviceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['device_id'],
        'deviceIp': json['device_ip'],
        'deviceName': json['device_name'],
    };
}

export function UpdateDeviceRequestToJSON(value?: UpdateDeviceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'device_ip': value.deviceIp,
        'device_name': value.deviceName,
    };
}


import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import Button from "../../components/Button/Button";
import TableLine from "../../components/TableLine/TableLine";
import {Pagination} from "../../components/Pagination/Pagination";
import { actionCreators } from "../../state/actions";
import FilterData from "../../components/FilterData/FilterData";
import {copyToClipboard} from "../../constants/utilFunctions";
import {Device, FindDevices} from "../../api/device";

export const SessionDeviceList = (props: any) => {
  const [data, setData] = useState<FindDevices>({
    maxPages: 0,
    devices: []
  });

  const {devices} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const {getDevices} = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    setData(devices);
  }, [devices]);

  const filteredData = () => {
    return data.devices.map((el: Device) => {
      return [
        el.deviceName,
        el.deviceIp,
        {
          icon: "copy",
          className: "reverse",
          value: el.deviceToken,
          asText: true,
          onClick: () => {
                    copyToClipboard(el.deviceToken);
                  }
                }
            ];
        });
    }

    const columns = "1fr 1fr 4fr 60px";

    return <main>
        <header>
            <div className="info">
                <h1 style={{fontSize: "30px"}}>Выбор устройства</h1>
                <FilterData placeHolder={"Поиск устройства"} filterField={"deviceName_filter"}
                            filterFunction={getDevices}/>
            </div>
            <Button value={"X"} onClick={props.onClose}/>
        </header>
        <div className="table">
            <TableLine data={['Имя устройства', 'IP', "API TOKEN", ""]} isHeader={true} columns={columns}/>
            <div className="table__container">
              {filteredData().map((el: any, i: number) => <TableLine data={el} key={i} columns={columns}
                                                                     onClick={() => props.setDevice(data.devices[i])}/>)}
              {!filteredData().length && <h2>Вы ещё не добавили устройства</h2>}
            </div>
        </div>

      <Pagination max_pages={devices.maxPages}
                  onChange={(limit: number, page: number) => {
                    getDevices({limit, page});
                  }}/>
    </main>;
}

import React from "react";
import Unity, {UnityContext} from "react-unity-webgl";

const initialState = {};
const BASE_URL = "./stop_fire_map_renderer/Build1/stop_fire_map_renderer";

export class UnityContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.unityContext = new UnityContext({
      loaderUrl: `${BASE_URL}.loader.js`,
      dataUrl: `${BASE_URL}.data.unityweb`,
      frameworkUrl: `${BASE_URL}.framework.js.unityweb`,
      codeUrl: `${BASE_URL}.wasm.unityweb`
    })

    this.unityContext.on("loaded", () => {
      this.setState({isLoaded: true});
      setTimeout(() => {
        const apiHost = `${localStorage.getItem('stopfireurl')}/`;
        this.unityContext.send('MapVisualizer', 'SetApiHost',  apiHost);
        this.unityContext.send('MapVisualizer', 'SetToken', props.token);
        this.unityContext.send('MapVisualizer', 'SetSession', props.session);
        this.unityContext.send('MapVisualizer', 'SetUser', props.user);
      }, 200)
    });

    this.unityContext.on("progress", (progression) => this.setState({progress: progression}));

    // Передаем в родителя функцию выхода
    props.quitRef.current = this.dropUnity;
  }

  dropUnity = async () => await this.unityContext.quitUnityInstance();

  handleOnWheel = (e) => {
    this.unityContext.send('Main Camera', 'AddWheelDelta', e.deltaY);
  };

  render() {
    return <div onWheel={(e) => this.handleOnWheel(e)}>
      <Unity unityContext={this.unityContext} style={{width: "512px", height: "512px", overflow: "hidden"}}/>
    </div>
  }
}

import "./PasswordInput.css";
import {useState} from "react";
import hide from "../../images/icons/in_game/hide.svg";
import show from "../../images/icons/in_game/show.svg";

interface IPasswordInput {
    value: string,
    onChange?: Function
}

export const PasswordInput = (props: IPasswordInput) => {
    const [open, setOpen] = useState(false);

    return <div className={"password-input"}>
        {/* @ts-ignore */}
        <input type={open ? "text" : "password"} defaultValue={props.value}
               className={"form-input password" + (open ? " open" : "")}
               readOnly={!open} onChange={(e) => {
                   if (!props.onChange)
                       return false;

                   props.onChange(e.target.value);
        }}/>
        <img src={open ? hide : show} alt={"icon"} onClick={() => {
            setOpen(!open);
        }} />
    </div>
}

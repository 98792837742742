import React, {useEffect, useState} from 'react';
import ICONS from "../../constants/icons";

interface IIconProps {
    icon: string,
    disabled?: boolean,
    hover?: boolean,
    selected?: boolean,
    cantHover?: boolean,
    sizes?: {h: number, w: number}
}

const Icon = (props: IIconProps) => {
    let source = ICONS[props.icon];
    let defaultSrc = props.disabled ? source.disabled : source.base;
    const [src, setSrc] = useState(defaultSrc)

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        source = ICONS[props.icon];

        let newSrc: string;

        if (props.disabled)
            newSrc = source.disabled;
        else if (props.selected)
            newSrc = source.selected;
        else if (props.hover && !props.cantHover)
            newSrc = source.hover;
        else
            newSrc = source.base;

        setSrc(newSrc);

    }, [props.icon, props.hover, props.selected])

    const onHover = () => {
        if (src !== source.hover && !props.disabled && !props.cantHover)
            setSrc(source.hover);
    }

    const offHover = () => {
        if (!props.cantHover)
            setSrc(defaultSrc);
    }

    const onPress = () => {
        setSrc(source.selected)
    }

    const sizes = {
        width: props.sizes ? props.sizes.w : "auto",
        height: props.sizes ? props.sizes.h : "auto"
    };

    return (
        <img src={src} alt={"icon"} className={"icon i-" + props.icon} {...sizes}
            onMouseMove={onHover} onMouseLeave={offHover}
            onMouseDown={onPress} onMouseUp={onHover} />
    );
};

export default Icon;

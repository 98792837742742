/* tslint:disable */
/* eslint-disable */
/**
 * Session.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../../runtime';
import type {
  CreateSession,
  CreateSession200Response,
  FindSessions200Response,
  ServerError,
  State,
  UpdateSession,
} from '../models';
import {
    CreateSessionFromJSON,
    CreateSessionToJSON,
    CreateSession200ResponseFromJSON,
    CreateSession200ResponseToJSON,
    FindSessions200ResponseFromJSON,
    FindSessions200ResponseToJSON,
    ServerErrorFromJSON,
    ServerErrorToJSON,
    StateFromJSON,
    StateToJSON,
    UpdateSessionFromJSON,
    UpdateSessionToJSON,
} from '../models';

export interface CreateSessionRequest {
    body?: CreateSession;
}

export interface DeleteSessionRequest {
    body?: CreateSession200Response;
}

export interface FindSessionsRequest {
    idsFilter?: Array<string>;
    nameFilter?: string;
    statesFilter?: Array<State>;
    userIdFilter?: string;
    moderatorIdFilter?: string;
    deviceIdFilter?: string;
    limit?: number;
    page?: number;
}

export interface UpdateSessionRequest {
    body?: UpdateSession;
}

/**
 * 
 */
export class SessionApi extends runtime.BaseAPI {

    /**
     * Create Session
     */
    async createSessionRaw(requestParameters: CreateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSession200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSessionToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSession200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Session
     */
    async createSession(requestParameters: CreateSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSession200Response> {
        const response = await this.createSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Session
     */
    async deleteSessionRaw(requestParameters: DeleteSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/session`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSession200ResponseToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Session
     */
    async deleteSession(requestParameters: DeleteSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Find Sessions
     */
    async findSessionsRaw(requestParameters: FindSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindSessions200Response>> {
        const queryParameters: any = {};

        if (requestParameters.idsFilter) {
            queryParameters['ids_filter'] = requestParameters.idsFilter;
        }

        if (requestParameters.nameFilter !== undefined) {
            queryParameters['name_filter'] = requestParameters.nameFilter;
        }

        if (requestParameters.statesFilter) {
            queryParameters['states_filter'] = requestParameters.statesFilter;
        }

        if (requestParameters.userIdFilter !== undefined) {
            queryParameters['user_id_filter'] = requestParameters.userIdFilter;
        }

        if (requestParameters.moderatorIdFilter !== undefined) {
            queryParameters['moderator_id_filter'] = requestParameters.moderatorIdFilter;
        }

        if (requestParameters.deviceIdFilter !== undefined) {
            queryParameters['device_id_filter'] = requestParameters.deviceIdFilter;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["device_token"] = this.configuration.apiKey("device_token"); // DeviceAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/session`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindSessions200ResponseFromJSON(jsonValue));
    }

    /**
     * Find Sessions
     */
    async findSessions(requestParameters: FindSessionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindSessions200Response> {
        const response = await this.findSessionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Session
     */
    async updateSessionRaw(requestParameters: UpdateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["device_token"] = this.configuration.apiKey("device_token"); // DeviceAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/session`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSessionToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Session
     */
    async updateSession(requestParameters: UpdateSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSessionRaw(requestParameters, initOverrides);
    }

}

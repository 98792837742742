
import {
  CHANGE_PASSWORD,
  CHANGE_SESSION_STATE,
  CREATE_DEVICE,
  CREATE_SESSION,
  CREATE_USER,
  DELETE_DEVICE,
  DELETE_SESSION,
  DELETE_USER,
  DROP_SESSION,
  EDIT_SESSION,
  EDIT_USER,
  GET_DEVICES,
  GET_FIRE_MODEL_PLAYER,
  GET_PLAYER_STATISTICS,
  GET_SESSIONS,
  GET_USERS,
  LOGIN,
  LOGOUT,
  RESET_FIRE_MODEL_PLAYER,
  SELECT_SESSION,
  SET_ACTIVE_TAB,
  SET_IS_FULL
} from "../actionTypes";
import {getData} from "../requests";
import {getStopFire, removeEmptyKeys, setStopFire} from "../../constants/utilFunctions";
import {AppController} from "./AppController";
import {
  ChangeUserPasswordRequest,
  CreateUserRequest,
  DeleteUserRequest,
  LoginData,
  UpdateUserRequest
} from "../../api/user_administrator";
import {CreateDeviceRequest, DeleteDeviceRequest} from "../../api/device";
import {CreateSession, CreateSession200Response, Session, UpdateSession} from "../../api/session";
import {FindPlayersDataRequest} from "../../api/node";

async function errorCallback(e: any) {
  if (e.response && e.response.status === 401) {
    return resetToken();
  }

  console.log(e)
  console.log(e.response);
  return Promise.reject(e);
}

export const setActiveTab = (id: Number) => {
  const action: MenuTabAction = {
    type: SET_ACTIVE_TAB,
    id
  };

  return ((dispatch: DispatchMenuTabType) => {
    dispatch(action)
  });
}

export const setIsFull = (isFull: boolean) => {
  const action: MenuTabAction = {
    type: SET_IS_FULL,
    isFull
  };

  return ((dispatch: DispatchMenuTabType) => {
    dispatch(action)
  });
}

export const resetToken = async () => {
  const refreshToken = getStopFire('userdata').refreshToken;
  const res = await AppController.authorization.login({
    loginData: {
      loginDataType: "refresh_login",
      refreshToken
    }
  });

  setStopFire('userdata', res);
  window.location.reload();
}

// Authorization
export const loginAction = (data: LoginData) => async (dispatch: DispatchAuthorization) => {
  try {
    const response: any = await AppController.authorization.login({
      loginData: data
    }).catch(() => {
      return {
        message: "wrong login or password"
      };
    })

    response["logged_at"] = Date.now();
    setStopFire("userdata", response);

    dispatch({
      type: LOGIN,
      payload: response
    });
  } catch (e) {
    return errorCallback(e);
  }
}

export const logoutAction = () => {
  return ((dispatch: DispatchAuthorization) => {
    dispatch({
      type: LOGOUT
    });

    window.location.reload();
  });
}

// Users
export const getUsers = (config = {}) => async (dispatch: DispatchUser) => {
  try {
    const response = await AppController.userAdmin.findUsers(config);

    dispatch({
      type: GET_USERS,
      payload: response
    });
    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const createUser = (data: CreateUserRequest) => async (dispatch: DispatchUser) => {
  try {
    const response = await AppController.userAdmin.createUser({
      createUserRequest: data
    });

    dispatch({
      type: CREATE_USER,
      payload: response
    });

    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const deleteUser = (data: DeleteUserRequest) => async (dispatch: DispatchUser) => {
  try {
    const response = await AppController.userAdmin.deleteUser(data);

    dispatch({
      type: DELETE_USER,
      payload: response
    })

    return Promise.resolve();
  } catch (e) {
    return errorCallback(e);
  }
}

export const editUser = (data: UpdateUserRequest) => async (dispatch: DispatchUser) => {
  try {
    const response = await AppController.userAdmin.updateUser({
      updateUserRequest: data
    });

    dispatch({
      type: EDIT_USER,
      payload: response
    })

    return Promise.resolve();
  } catch (e) {
    return errorCallback(e);
  }
}

export const changePassword = (data: ChangeUserPasswordRequest) => async (dispatch: DispatchUser) => {
  try {
    const response = await AppController.userAdmin.changeUserPassword({
      changeUserPasswordRequest: data
    });

    dispatch({
      type: CHANGE_PASSWORD,
      payload: response
    })
  } catch (e) {
    return errorCallback(e);
  }
}

// Devices
export const getDevices = (config = {}) => async (dispatch: DispatchDevice) => {
  try {
    const response = await AppController.devices.findDevices(config);

    dispatch({
      type: GET_DEVICES,
      payload: response
    });

    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const createDevice = (data: CreateDeviceRequest) => async (dispatch: DispatchDevice) => {
  try {
    const response = await AppController.devices.createDevice({
      body: data
    });

    dispatch({
      type: CREATE_DEVICE,
      payload: response
    });

    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const deleteDevice = (data: DeleteDeviceRequest) => async (dispatch: DispatchDevice) => {
  try {
    const response = await AppController.devices.deleteDevice(data);

    dispatch({
      type: DELETE_DEVICE,
      payload: response
    })

    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

// SessionList
export const getSessionList = (config = {}) => async (dispatch: DispatchSession) => {
  try {
    const response = await getSessions({
      ...config,
      statesFilter: ['New', 'Prepared', 'Initializing', 'Start', 'Pause'],
    });

    dispatch({
      type: GET_SESSIONS,
      payload: response
    });
    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const getSessions = async (config = {}) => {
  try {
    const response = await AppController.session.findSessions(config);
    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const deleteSession = (data: CreateSession200Response) => async (dispatch: DispatchSession) => {
  try {
    const response = await AppController.session.deleteSession({
      body: data
    });

    dispatch({
      type: DELETE_SESSION,
      payload: response
    })

    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const createSession = (data: CreateSession) => async (dispatch: DispatchSession) => {
  try {
    const response = await AppController.session.createSession({
      body: data
    });

    dispatch({
      type: CREATE_SESSION,
      payload: response
    })

    return Promise.resolve();
  } catch (e) {
    return errorCallback(e);
  }
}

export const updateSession = (data: UpdateSession) => async (dispatch: DispatchSession) => {
  try {
    // @ts-ignore
    if (!window.hasChangedConfig) {
      delete data.config;
    }

    const response = await AppController.session.updateSession({
      body: removeEmptyKeys(data),
    });

    dispatch({
      type: EDIT_SESSION,
      payload: response
    })

    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const changeSessionState = (data: UpdateSession) => async (dispatch: DispatchSessionState) => {
  try {
    return await AppController.session.updateSession({
      body: data
    }).then(() => {
      dispatch({
        type: CHANGE_SESSION_STATE,
        payload: {
          new_state: data.newState
        }
      });
      return Promise.resolve();
    }).catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
  } catch (e) {
    return errorCallback(e);
  }
}

export const selectSession = (data: Session) => (dispatch: DispatchCurrentSession) => {
  const action: CurrentSessionAction = {
    type: SELECT_SESSION,
    payload: {
      ...data
    }
  };

  dispatch(action);
  return Promise.resolve();
}

export const dropSession = () => (dispatch: DispatchCurrentSession) => {
  const action: CurrentSessionAction = {
    type: DROP_SESSION,
    payload: {}
  };

  dispatch(action);

  return Promise.resolve();
}

export const getPlayerInfo = (data: FindPlayersDataRequest) => async (dispatch: DispatchFireModelPlayer) => {
  try {
    const {sessionId, userIds} = data;
    const response = await AppController.fireModelMasterNode.findPlayersData({
      sessionId, userIds
    });

    dispatch({
      type: GET_FIRE_MODEL_PLAYER,
      payload: response
    });

    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const resetFireModelPlayer = () => (dispatch: DispatchFireModelPlayer) => {
  dispatch({
    type: RESET_FIRE_MODEL_PLAYER,
    payload: []
  })
};

export const getPlayerStatistics = (config = {}) => async (dispatch: DispatchPlayerStatistics) => {
  try {
    const response = await getStatistics(config);

    dispatch({
      type: GET_PLAYER_STATISTICS,
      payload: response
    });
    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const getStatistics = async (config = {}) => {
  try {
    const response = await AppController.playerStatistics.findPlayersStatistic(config);
    return Promise.resolve(response);
  } catch (e) {
    return errorCallback(e);
  }
}

export const getVersion = async () => {
  try {
    const response = await getData("version");
    return Promise.resolve<string>(response.version);
  } catch (e) {
    return errorCallback(e);
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Fire Model Master Node.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../../runtime';
import type {
  CreateFireModelRequest,
  FindModelDataRequest,
  FindPlayersData,
  ProcessorHealthcheck200Response,
  ServerError,
  SetPutOutFireRequest,
  UpdatePlayerPositionRequest,
  UpdateState200Response,
  UpdateStateRequest,
} from '../models';
import {
    CreateFireModelRequestFromJSON,
    CreateFireModelRequestToJSON,
    FindModelDataRequestFromJSON,
    FindModelDataRequestToJSON,
    FindPlayersDataFromJSON,
    FindPlayersDataToJSON,
    ProcessorHealthcheck200ResponseFromJSON,
    ProcessorHealthcheck200ResponseToJSON,
    ServerErrorFromJSON,
    ServerErrorToJSON,
    SetPutOutFireRequestFromJSON,
    SetPutOutFireRequestToJSON,
    UpdatePlayerPositionRequestFromJSON,
    UpdatePlayerPositionRequestToJSON,
    UpdateState200ResponseFromJSON,
    UpdateState200ResponseToJSON,
    UpdateStateRequestFromJSON,
    UpdateStateRequestToJSON,
} from '../models';

export interface CreateFireModelOperationRequest {
    body: CreateFireModelRequest;
}

export interface FindModelDataOperationRequest {
    body?: FindModelDataRequest;
}

export interface FindPlayersDataRequest {
    sessionId: string;
    userIds: Array<string>;
}

export interface ProcessorHealthcheckRequest {
    sessionId: string;
}

export interface SetPutOutFireOperationRequest {
    body?: SetPutOutFireRequest;
}

export interface UpdatePlayerPositionOperationRequest {
    body?: UpdatePlayerPositionRequest;
}

export interface UpdateStateOperationRequest {
    body?: UpdateStateRequest;
}

/**
 * 
 */
export class FireModelMasterNodeApi extends runtime.BaseAPI {

    /**
     * Create Fire Model
     */
    async createFireModelRaw(requestParameters: CreateFireModelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createFireModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/fire_model_master_node`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFireModelRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Fire Model
     */
    async createFireModel(requestParameters: CreateFireModelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createFireModelRaw(requestParameters, initOverrides);
    }

    /**
     * Find Model Data
     */
    async findModelDataRaw(requestParameters: FindModelDataOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["device_token"] = this.configuration.apiKey("device_token"); // DeviceAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/fire_model_master_node`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FindModelDataRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Find Model Data
     */
    async findModelData(requestParameters: FindModelDataOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.findModelDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find Player Data
     */
    async findPlayersDataRaw(requestParameters: FindPlayersDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindPlayersData>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling findPlayersData.');
        }

        if (requestParameters.userIds === null || requestParameters.userIds === undefined) {
            throw new runtime.RequiredError('userIds','Required parameter requestParameters.userIds was null or undefined when calling findPlayersData.');
        }

        const queryParameters: any = {};

        if (requestParameters.sessionId !== undefined) {
            queryParameters['session_id'] = requestParameters.sessionId;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["device_token"] = this.configuration.apiKey("device_token"); // DeviceAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/fire_model_master_node/player`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindPlayersDataFromJSON(jsonValue));
    }

    /**
     * Find Player Data
     */
    async findPlayersData(requestParameters: FindPlayersDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindPlayersData> {
        const response = await this.findPlayersDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Processor Healthcheck
     */
    async processorHealthcheckRaw(requestParameters: ProcessorHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcessorHealthcheck200Response>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling processorHealthcheck.');
        }

        const queryParameters: any = {};

        if (requestParameters.sessionId !== undefined) {
            queryParameters['session_id'] = requestParameters.sessionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/fire_model_master_node/processor_healthcheck`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessorHealthcheck200ResponseFromJSON(jsonValue));
    }

    /**
     * Processor Healthcheck
     */
    async processorHealthcheck(requestParameters: ProcessorHealthcheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcessorHealthcheck200Response> {
        const response = await this.processorHealthcheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Put Out Fire
     */
    async setPutOutFireRaw(requestParameters: SetPutOutFireOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["device_token"] = this.configuration.apiKey("device_token"); // DeviceAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/fire_model_master_node/set_put_out_fire`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPutOutFireRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Put Out Fire
     */
    async setPutOutFire(requestParameters: SetPutOutFireOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPutOutFireRaw(requestParameters, initOverrides);
    }

    /**
     * Update Player Position
     */
    async updatePlayerPositionRaw(requestParameters: UpdatePlayerPositionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["device_token"] = this.configuration.apiKey("device_token"); // DeviceAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/fire_model_master_node/player`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePlayerPositionRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Player Position
     */
    async updatePlayerPosition(requestParameters: UpdatePlayerPositionOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePlayerPositionRaw(requestParameters, initOverrides);
    }

    /**
     * Update State
     */
    async updateStateRaw(requestParameters: UpdateStateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateState200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/fire_model_master_node/state`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStateRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateState200ResponseFromJSON(jsonValue));
    }

    /**
     * Update State
     */
    async updateState(requestParameters: UpdateStateOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateState200Response> {
        const response = await this.updateStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

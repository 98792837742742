/* tslint:disable */
/* eslint-disable */
/**
 * Device.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Device } from './Device';
import {
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
} from './Device';

/**
 * 
 * @export
 * @interface FindDevices
 */
export interface FindDevices {
    /**
     * 
     * @type {Array<Device>}
     * @memberof FindDevices
     */
    devices: Array<Device>;
    /**
     * 
     * @type {number}
     * @memberof FindDevices
     */
    maxPages: number;
}

/**
 * Check if a given object implements the FindDevices interface.
 */
export function instanceOfFindDevices(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "devices" in value;
    isInstance = isInstance && "maxPages" in value;

    return isInstance;
}

export function FindDevicesFromJSON(json: any): FindDevices {
    return FindDevicesFromJSONTyped(json, false);
}

export function FindDevicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindDevices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'devices': ((json['devices'] as Array<any>).map(DeviceFromJSON)),
        'maxPages': json['max_pages'],
    };
}

export function FindDevicesToJSON(value?: FindDevices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'devices': ((value.devices as Array<any>).map(DeviceToJSON)),
        'max_pages': value.maxPages,
    };
}


import {CHANGE_PASSWORD, CREATE_USER, DELETE_USER, EDIT_USER, GET_USERS} from "../actionTypes";
import {FindUsers200Response} from "../../api/user_administrator";

const initialState: FindUsers200Response = {
    maxPages: 0,
    users: []
};

const userReducer = (state: FindUsers200Response = initialState, action: UserAction) => {
    switch (action.type) {
        case GET_USERS:
            return {...action.payload};
        case CREATE_USER:
            return state;
        case EDIT_USER:
        case DELETE_USER:
        case CHANGE_PASSWORD:
            return {...state, ...action.payload};
        default:
            return state;
    }
}

export default userReducer;

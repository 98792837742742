import React, {MouseEventHandler} from 'react';
import "./Button.css";
import Icon from "../Icon/Icon";

interface IButtonProps {
    value?: string | number;
    icon?: string;
    onClick?: MouseEventHandler | undefined;
    disabled?: boolean;
    className?: string;
    iconSizes?: {h: number, w: number};
    title?: string;
}

const Button = (props: IButtonProps) => {
    const onClick = (event: any) => {
        if (!props.disabled && props.onClick !== undefined) {
            props.onClick(event);
        }
    }

    let className = "form-button ";
    if (props.disabled)
        className += "disabled ";
    if (props.className)
        className += props.className;

    return (
        <button className={className} onClick={onClick} title={props.title}>
            {props.icon && <Icon disabled={props.disabled} icon={props.icon} sizes={props.iconSizes}/> } {props.value}
        </button>
    );
};

export default Button;

import React, {useState} from 'react';
import RANGE_ICONS from "../../constants/sliderIcons";
import Button from "../Button/Button";
import {Slider} from "@mui/material";
import "./RangeSlider.css";

interface IRangeSliderProps {
    header: string;
    icon: string;
    min: number;
    max: number;
    step?: number;
    defaultValue?: number;
    recommendedValue?: number;
    onChange: Function;
}

const RangeSlider = (props: IRangeSliderProps) => {
    const [current, setCurrent] = useState<number | number[]>(props.defaultValue ?? 0);

    const icons = RANGE_ICONS[props.icon];
    let recValue = props.recommendedValue ?  props.recommendedValue  / props.max : props.max / 2;
    recValue = recValue * props.max;
    const marks = [
        {
            value: props.min,
            label: props.min
        },
        {
            value: recValue,
            label: recValue
        },
        {
            value: props.max,
            label: props.max
        }
    ];

    const change = (value: number | number[]) => {
        setCurrent(value);
        props.onChange(value);
    }

    return (
        <div className={"range-slider"}>
            <p className={"p1"}>{props.header}</p>
            <div className="slide-container">
                <img src={icons.small} alt="icon"/>
                <div className="slider">
                    <Slider
                        aria-label="Custom marks"
                        value={current}
                        step={props.step ?? 10}
                        min={props.min ?? 0}
                        max={props.max ?? 100}
                        marks={marks}
                        onChange={(event, value) => {
                            change(value)
                        }}
                    />
                </div>
                <img src={icons.big} alt="icon"/>

                <Button className={"only-icon output"} value={current.toString()}/>
                <Button className={"only-icon"} icon={"clear"} onClick={() => change(recValue)}/>
            </div>
        </div>
    );
};

export default RangeSlider;

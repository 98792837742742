/* tslint:disable */
/* eslint-disable */
/**
 * Players Statistics.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { CreatePlayer } from './CreatePlayer';
import {
    CreatePlayerFromJSON,
    CreatePlayerFromJSONTyped,
    CreatePlayerToJSON,
} from './CreatePlayer';

/**
 * 
 * @export
 * @interface CreatePlayersStatisticRequest
 */
export interface CreatePlayersStatisticRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePlayersStatisticRequest
     */
    sessionId: string;
    /**
     * 
     * @type {Array<CreatePlayer>}
     * @memberof CreatePlayersStatisticRequest
     */
    players: Array<CreatePlayer>;
}

/**
 * Check if a given object implements the CreatePlayersStatisticRequest interface.
 */
export function instanceOfCreatePlayersStatisticRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "players" in value;

    return isInstance;
}

export function CreatePlayersStatisticRequestFromJSON(json: any): CreatePlayersStatisticRequest {
    return CreatePlayersStatisticRequestFromJSONTyped(json, false);
}

export function CreatePlayersStatisticRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePlayersStatisticRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['session_id'],
        'players': ((json['players'] as Array<any>).map(CreatePlayerFromJSON)),
    };
}

export function CreatePlayersStatisticRequestToJSON(value?: CreatePlayersStatisticRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_id': value.sessionId,
        'players': ((value.players as Array<any>).map(CreatePlayerToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * User Administrator.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../../runtime';
import type {
  ChangeUserPasswordRequest,
  CreateUser200Response,
  CreateUserRequest,
  FindUsers200Response,
  ServerError,
  UpdateUserRequest,
  UserRole,
} from '../models';
import {
    ChangeUserPasswordRequestFromJSON,
    ChangeUserPasswordRequestToJSON,
    CreateUser200ResponseFromJSON,
    CreateUser200ResponseToJSON,
    CreateUserRequestFromJSON,
    CreateUserRequestToJSON,
    FindUsers200ResponseFromJSON,
    FindUsers200ResponseToJSON,
    ServerErrorFromJSON,
    ServerErrorToJSON,
    UpdateUserRequestFromJSON,
    UpdateUserRequestToJSON,
    UserRoleFromJSON,
    UserRoleToJSON,
} from '../models';

export interface ChangeUserPasswordOperationRequest {
    changeUserPasswordRequest: ChangeUserPasswordRequest;
}

export interface CreateUserOperationRequest {
    createUserRequest: CreateUserRequest;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface FindUsersRequest {
    userIdFilter?: Array<string>;
    fullNameFilter?: string;
    userRoleFilter?: UserRole;
    limit?: number;
    page?: number;
    unscoped?: boolean;
}

export interface UpdateUserOperationRequest {
    updateUserRequest: UpdateUserRequest;
}

/**
 *
 */
export class UserAdministratorApi extends runtime.BaseAPI {

    /**
     * Change User Password
     */
    async changeUserPasswordRaw(requestParameters: ChangeUserPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changeUserPasswordRequest === null || requestParameters.changeUserPasswordRequest === undefined) {
            throw new runtime.RequiredError('changeUserPasswordRequest','Required parameter requestParameters.changeUserPasswordRequest was null or undefined when calling changeUserPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user_administrator/change_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeUserPasswordRequestToJSON(requestParameters.changeUserPasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change User Password
     */
    async changeUserPassword(requestParameters: ChangeUserPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeUserPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Create User
     */
    async createUserRaw(requestParameters: CreateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUser200Response>> {
        if (requestParameters.createUserRequest === null || requestParameters.createUserRequest === undefined) {
            throw new runtime.RequiredError('createUserRequest','Required parameter requestParameters.createUserRequest was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/user_administrator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserRequestToJSON(requestParameters.createUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUser200ResponseFromJSON(jsonValue));
    }

    /**
     * Create User
     */
    async createUser(requestParameters: CreateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUser200Response> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete User
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/user_administrator`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete User
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     * Find Users
     */
    async findUsersRaw(requestParameters: FindUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindUsers200Response>> {
        const queryParameters: any = {};

        if (requestParameters.userIdFilter) {
            queryParameters['user_id_filter'] = requestParameters.userIdFilter;
        }

        if (requestParameters.fullNameFilter !== undefined) {
            queryParameters['full_name_filter'] = requestParameters.fullNameFilter;
        }

        if (requestParameters.userRoleFilter !== undefined) {
            queryParameters['user_role_filter'] = requestParameters.userRoleFilter;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.unscoped !== undefined) {
            queryParameters['unscoped'] = requestParameters.unscoped;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/user_administrator`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindUsers200ResponseFromJSON(jsonValue));
    }

    /**
     * Find Users
     */
    async findUsers(requestParameters: FindUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindUsers200Response> {
        const response = await this.findUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User
     */
    async updateUserRaw(requestParameters: UpdateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateUserRequest === null || requestParameters.updateUserRequest === undefined) {
            throw new runtime.RequiredError('updateUserRequest','Required parameter requestParameters.updateUserRequest was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/user_administrator`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestToJSON(requestParameters.updateUserRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update User
     */
    async updateUser(requestParameters: UpdateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserRaw(requestParameters, initOverrides);
    }

}

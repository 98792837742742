import {AuthorizationApi, UserAdministratorApi} from "../../api/user_administrator";
import {DeviceApi} from "../../api/device";
import {SessionApi} from "../../api/session";
import {FireModelMasterNodeApi} from "../../api/master_node";
import {PlayersStatisticApi} from "../../api/player_statistics";

export const AppController = {
  authorization: new AuthorizationApi(),
  userAdmin: new UserAdministratorApi(),
  devices: new DeviceApi(),
  session: new SessionApi(),
  fireModelMasterNode: new FireModelMasterNodeApi(),
  playerStatistics: new PlayersStatisticApi(),
};

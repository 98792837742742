import React, {useEffect, useRef, useState} from 'react';
import {Sidebar} from "../../components/Sidebar/Sidebar";
import "./Simulation.css"
import MapIndicators from "../../components/MapIndicators/MapIndicators";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../components/Button/Button";
import {useHistory} from "react-router-dom";
import {bindActionCreators} from "redux";
import {actionCreators} from '../../state/actions';
import Timer from "../../components/Timer/Timer";
import Alert from "../../components/Alert/Alert";
import {Loader} from "../../components/Loader/Loader";
import {UnityContainer} from "../../components/UnityContainer/UnityContainer";
import {getSessions, getStatistics} from "../../state/actions/actionCreators";
import GraphsBlock from "../../components/GraphsBlock/GraphsBlock";
import {getStopFire} from "../../constants/utilFunctions";

interface IErrorMessage {
  message: string,
  title?: string,
  onOk?: Function
}

const Simulation = () => {
  const {currentSession, fireModelPlayer} = useSelector((state: any) => state);
  const [devicePopup, setDevicePopup] = useState(false);
  const [error, setError] = useState<IErrorMessage>({message: ""});
  const [hasClicked, setHasClicked] = useState(false);
  const canContinue = useRef(true);
  const quitRef = useRef<any>(async () => {
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    dropSession,
    changeSessionState,
    getPlayerInfo,
    resetFireModelPlayer,
    selectSession,
  } = bindActionCreators(actionCreators, dispatch);

  const goBack = () => {
    // Вызываем quit в юнити, потом уже переходим
    quitRef.current().then(() => {
      new Promise((resolve) => {
        resolve(dropSession());
      }).then(() => {
        resetFireModelPlayer();
      }).then(() => {
        history.push("/sessionList");
      });
    });
  }

  const heartBeat = async () => {
    if (!currentSession.id || currentSession.state !== "Start" || !canContinue.current) {
      return;
    }

    const playerInfo = await getPlayerInfo({
      sessionId: currentSession.id,
      userIds: [currentSession.usersWithDevices[0].user.userId]
    });

    if (!playerInfo.hasOwnProperty("message"))
      return;

    canContinue.current = false;

    const singleSession: any = await getSessions({
      id: currentSession.id
    });

    // Если сессия ещё жива – всё ок
    if (singleSession.sessions.length) {
      canContinue.current = true;
    }
  }

  const heartBeatHandler = async () => {
    try {
      await heartBeat();
    } catch (e) {
      // @ts-ignore
      clearInterval(window.interval);
      quitRef.current();

      const stats: any = await getStatistics({
        sessionIdFilter: currentSession.id
      });
      // Если она появилась, то просто уходим в финальный экран
      if (stats?.playerStatistics.length) {
        setError({
          title: "Весь огонь потушен",
          message: "",
          onOk: () => {
            history.push("/end");
          }
        });
      } else { // Иначе выдаем ошибку
        setError({
          title: "Произошла ошибка подключения к игре",
          message: 'Возвращаемся к списку сессий',
          onOk: () => {
            goBack();
          }
        });
      }
    }
  }

  useEffect(() => {
    if (!currentSession.id) {
      history.push("/sessionList");
    }
  }, [currentSession]);

  useEffect(() => {
    // @ts-ignore
    window.interval = setInterval(() => heartBeatHandler(), 1000);

    let sessionPreparationHeartBeat: any = undefined;

    if (currentSession.state === "New") {
      sessionPreparationHeartBeat = setInterval(async () => {
        await getSessions({"idsFilter": currentSession.id})
          .then(res => {
            if (res) {
              let result = res.sessions[0];
              if (result.state === "Prepared") {
                clearInterval(sessionPreparationHeartBeat);
                selectSession(result);
              }
            }
          })
      }, 2000);
    }

    return () => {
      // @ts-ignore
      clearInterval(window.interval);
      if (sessionPreparationHeartBeat) {
        clearInterval(sessionPreparationHeartBeat);
      }
    }
  }, [fireModelPlayer, currentSession]);

  const getControls = () => {
    if (currentSession.state === "New" || currentSession.state === "Prepared") {
      return <Button disabled={currentSession.state === "New" || hasClicked}
                     value={"Начать"} className={"big-button"}
                     onClick={() => {
                       setHasClicked(true);
                       new Promise((resolve) => {
                         resolve(changeSessionState({
                           id: currentSession.id,
                           newState: "Start"
                         }));
                       }).catch(error => {
                         console.log(error);
                         let message = error?.message.match(/Size/) ? "Не указан размер ячейки!" : "Подробности в консоли";
                         setError({message});
                       }).finally(() => {
                         setHasClicked(false);
                       });
                     }}/>;
    }

    return <>
      <Timer/>
      <Button value={"Завершить"} disabled={hasClicked}
              className={"big-button"} onClick={() => {
        setHasClicked(true);
        quitRef.current().then(() => {
          new Promise(resolve => {
            resolve(changeSessionState({
              id: currentSession.id,
              newState: "Stop"
            }));
          }).then(() => {
            history.push("/end");
          }).finally(() => {
            setHasClicked(false);
          });
        });
      }}/>
    </>;
  }

  const getLoadingScreen = () => {
    return <div className={"setup-info"}>
      <h1>настройка сессии</h1>
      <p>для продолжения настройки сессии запустите Unity приложение<br/>и следуйте инструкциям в нём</p>
      <Loader/>
    </div>;
  }

  const getUnityContainer = () => {
    if (!currentSession.id) return null;
    const token = getStopFire('userdata')?.accessToken;
    return <UnityContainer quitRef={quitRef}
                           session={currentSession.id}
                           token={token}
                           user={currentSession.usersWithDevices[0].user.userId}/>;
  }

  return (
    <main className={"settings page"}>
      <Sidebar callback={quitRef.current}/>
      <main>
        <header>
          <div className="title">
            <Button icon={"left"} className={"icon-13"} onClick={goBack}/>
            <h1>Симуляция</h1>
          </div>
          {currentSession.state === "Stop" ? null : <div className="controls">{getControls()}</div>}
        </header>
        <div className="gameplay">
          <div className="flame-info">
            {currentSession.state !== "New" && <MapIndicators/>}
          </div>

          {currentSession.state === "New" || currentSession.state === "Prepared" ?
            getLoadingScreen() : getUnityContainer()}

          <GraphsBlock initialOxygen={currentSession.config.startingPlayersOxygen ?? 50000}
                       openDeviceInfo={() => setDevicePopup(true)}/>
        </div>
      </main>
      {devicePopup && <Alert className={"device-info"} title={"Устройство"} onOk={() => {
        setDevicePopup(false)
      }} html={`<p>${currentSession.usersWithDevices[0].device.deviceName || "DEVICE NAME"}</p>
                    <p>IP: ${currentSession.usersWithDevices[0].device.deviceIp}</p>
                    <p>${currentSession.usersWithDevices[0].device.deviceToken}</p>
            `} okText={"Закрыть"}/>}

      {(error.message || error.title) &&
          <Alert className={"error"} title={error.title || "Произошла ошибка!"} html={error.message} onOk={() => {
            if (error.onOk) {
              error.onOk();
            }

            setError({message: ""})
          }}/>}
    </main>
  );
};

export default Simulation;

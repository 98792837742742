import React, {useState} from 'react';
import INFO_ICONS from "../../constants/infoIcons";
import checked_icon from "../../images/icons/in_game/check_big.svg";
import "./Checkbox.css";

interface ICheckboxProps {
    icon?: string;
    label: string;
    onCheck: Function
}

const Checkbox = (props: ICheckboxProps) => {
    const [checked, setChecked] = useState(false);
    const style = {
        backgroundImage: "url("+checked_icon+")",
        backgroundColor: "var(--item-selected-color)",
        borderColor: "var(--item-selected-color)"
    }

    return (
        <div className={"checkbox__container"} onClick={() => {
            setChecked(!checked);
            props.onCheck()
        }}>
            <div className={"checkbox" + (checked ? " checked" : "")} style={checked ? style : {}} />
            {props.icon && <img src={INFO_ICONS[props.icon]} alt={"icon"} />}
            <h3>{props.label}</h3>
        </div>
    );
};

export default Checkbox;

/* tslint:disable */
/* eslint-disable */
/**
 * User Administrator.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    PasswordLogin,
    instanceOfPasswordLogin,
    PasswordLoginFromJSON,
    PasswordLoginFromJSONTyped,
    PasswordLoginToJSON,
} from './PasswordLogin';
import {
    RefreshLogin,
    instanceOfRefreshLogin,
    RefreshLoginFromJSON,
    RefreshLoginFromJSONTyped,
    RefreshLoginToJSON,
} from './RefreshLogin';

/**
 * @type LoginData
 * 
 * @export
 */
export type LoginData = { loginDataType: 'password_login' } & PasswordLogin | { loginDataType: 'refresh_login' } & RefreshLogin;

export function LoginDataFromJSON(json: any): LoginData {
    return LoginDataFromJSONTyped(json, false);
}

export function LoginDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['loginDataType']) {
        case 'password_login':
            return {...PasswordLoginFromJSONTyped(json, true), loginDataType: 'password_login'};
        case 'refresh_login':
            return {...RefreshLoginFromJSONTyped(json, true), loginDataType: 'refresh_login'};
        default:
            throw new Error(`No variant of LoginData exists with 'loginDataType=${json['loginDataType']}'`);
    }
}

export function LoginDataToJSON(value?: LoginData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['loginDataType']) {
        case 'password_login':
            return PasswordLoginToJSON(value);
        case 'refresh_login':
            return RefreshLoginToJSON(value);
        default:
            throw new Error(`No variant of LoginData exists with 'loginDataType=${value['loginDataType']}'`);
    }

}


import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import React, {useEffect, useState} from "react";
import {getRole} from "../../constants/utilFunctions";
import TableLine from "../../components/TableLine/TableLine";
import {Pagination} from "../../components/Pagination/Pagination";
import { actionCreators } from "../../state/actions";
import Button from "../../components/Button/Button";
import FilterData from "../../components/FilterData/FilterData";
import {FindUsers200Response, User} from "../../api/user_administrator";

export const SessionUserList = (props: any) => {
    const [data, setData] = useState<FindUsers200Response>({
        maxPages: 0,
        users: []
    });

    const {users} = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const {getUsers} = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        setData(users);
    }, [users]);

    const filteredData = () => {
        return data.users.map((el: User) => {
            const {userId, fullName, userRole} = el;

            return [
                userId?.slice(0, 5) ?? "",
                fullName,
                getRole(userRole)
            ];
        });
    }
    let columns = "0.5fr 3fr 2.8fr 0.1fr 0.1fr";

    return <main>
        <header>
            <div className="info">
                <h1 style={{fontSize: "30px"}}>Выбор пользователя</h1>
                <FilterData filterField={"fullNameFilter"} filterFunction={getUsers}/>
            </div>
            <Button value={"X"} onClick={props.onClose} />
        </header>
        <div className="table">
            <TableLine data={['id', 'Пользователь', "Роль", ""]} isHeader={true} columns={columns}/>
            <div className="table__container">
                {filteredData().map((el: any, i: number) => <TableLine data={el} key={i} columns={columns}
                                                                       onClick={() => props.setUser(data.users[i])}/>)}
            </div>
        </div>
        <Pagination max_pages={users.maxPages}
                    onChange={(limit: number, page: number) => {
                        getUsers({
                            userRoleFilter: "user",
                            limit,
                            page
                        });
                    }}/>
    </main>;
}

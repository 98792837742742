import {Sidebar} from "../../components/Sidebar/Sidebar";
import React, {useEffect, useState} from "react";
import Button from "../../components/Button/Button";
import FilterData from "../../components/FilterData/FilterData";
import Alert from "../../components/Alert/Alert";
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../state/actions";
import DeviceForm from "../../components/DeviceForm/DeviceForm";
import DeviceList from "../../layouts/DeviceList/DeviceList";
import {Device} from "../../api/device";

const Devices = () => {
    const [isAdd, setIsAdd] = useState(false);
    const [toDelete, setToDelete] = useState<Device | undefined>(undefined);
    const [alertText, setAlertText] = useState<any>(undefined);

    const dispatch = useDispatch();
    const {getDevices, deleteDevice, setActiveTab} = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        setActiveTab(1);
    }, []);

    return <main className={"devices page"}>
        <Sidebar/>
        <main>
            <header>
                <Button value={"Добавить устройство"} onClick={() => {
                    setIsAdd(true);
                }}/>

                <FilterData filterField={"deviceNameFilter"} filterFunction={getDevices}
                            placeHolder={"Искать устройство"}/>
            </header>
            <DeviceList setToDelete={setToDelete}/>
        </main>

        {isAdd && <DeviceForm onFinish={() => {
            setIsAdd(false);
            getDevices();
        }}/>}

        {toDelete && <Alert title={`Вы уверены, что хотите удалить устройство?`}
                            okText={"Подтвердить"} onOk={() => {

            new Promise(resolve => {
                resolve(deleteDevice({
                    deviceId: toDelete?.deviceId ?? ""
                }));
            }).then(() => {
                setToDelete(undefined);
                getDevices();
            }).catch(() => {
                setToDelete(undefined);
                setAlertText({
                    title: "Ошибка удаления",
                    html: "Данное устройство находится в активной сессии"
                });
            })

        }} cancelText={"Отмена"} onCancel={() => {
            setToDelete(undefined);
        }} maxWidth={376}/>}
        {alertText && <Alert title={alertText.title} html={alertText.html} lowerCase={true}
                             onOk={() => {
                                 setAlertText(undefined);
                             }}/>}
    </main>
}

export default Devices;

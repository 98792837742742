import React from 'react';
import "./GraphInfo.css";
import INFO_ICONS from "../../constants/infoIcons";
import {LineChart, Line} from 'recharts';

interface IGraphInfoProps {
    hasData: boolean;
    icon: string;
    value: number;
    data: number[];
}

const LINE_COLOR = {
    oxygen: "#40DDFF",
    liquid: "#437DF2",
    heartRate: "#F92B53"
}

const GraphInfo = (props: IGraphInfoProps) => {
    let icon = INFO_ICONS[props.icon];

    // @ts-ignore
    let strokeStyle = LINE_COLOR[props.icon];

    return (
        <div className={"graph-info"}>
            <div className="icon">
                <img src={icon} alt="icon"/>
            </div>
            <div>{props.value?.toFixed(2) || "–"}</div>

            <LineChart width={160} height={36} data={props.data}>
                <Line type="monotone" dataKey={(v) => v} dot={false}
                      stroke={strokeStyle} strokeWidth={2}/>
            </LineChart>
        </div>
    );
};

export default GraphInfo;

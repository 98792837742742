export const GET_SESSIONS = "GET_SESSION";
export const CREATE_SESSION = "CREATE_SESSION";
export const EDIT_SESSION = "EDIT_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const SINGLE_SESSION = "SINGLE_SESSION";
export const SET_SESSION_NAME = "SET_SESSION_NAME";

export const GET_FIRE_MODEL = "GET_FIRE_MODEL";
export const EDIT_FIRE_MODEL = "EDIT_FIRE_MODEL";
export const SET_FIRE_MODEL_PLAYER = "SET_FIRE_MODEL_PLAYER";
export const SET_PUT_OUT_FIRE = "SET_PUT_OUT_FIRE";
export const CHANGE_SESSION_STATE = "CHANGE_SESSION_STATE";

export const GET_DEVICES = "GET_DEVICES";
export const CREATE_DEVICE = "CREATE_DEVICE";
export const EDIT_DEVICE = "EDIT_DEVICE";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const BIND_DEVICE = "BIND_DEVICE";
export const VALIDATE_DEVICE = "VALIDATE_DEVICE";

export const GET_PLAYER_STATISTICS = "GET_PLAYER_STATISTICS";
export const GET_FIRE_MODEL_PLAYER = "GET_FIRE_MODEL_PLAYER";
export const RESET_FIRE_MODEL_PLAYER = "RESET_FIRE_MODEL_PLAYER";

export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_IS_FULL = "SET_IS_FULL";

export const SELECT_SESSION = "SELECT_SESSION";
export const DROP_SESSION = "DROP_SESSION";
export const SET_ACTIVE_ROLE = "SET_ACTIVE_ROLE";

import {CHANGE_SESSION_STATE, DROP_SESSION, SELECT_SESSION, SET_ACTIVE_ROLE} from "../actionTypes";

const initialState:CurrentSession = {
  id: "",
  state: "",
  activeRole: "",
  user: {
    userId: "",
    fullName: "",
  },
  device: {
    ip: "",
    name: "",
    token: "",
  },
  config: {}
};

const currentSessionReducer = (state: CurrentSession = initialState, action: CurrentSessionAction) => {
    switch (action.type) {
        case DROP_SESSION:
            return initialState;
        case SELECT_SESSION:
            return {
                ...action.payload
            };
        case CHANGE_SESSION_STATE:
            return {
                ...state,
                state: action.payload.new_state
            };
        case SET_ACTIVE_ROLE:
            return {
                ...state,
              activeRole: action.payload
            };
        default:
            return state;
    }
}

export default currentSessionReducer;

import {LOGIN, LOGOUT} from "../actionTypes";
import {setStopFire} from "../../constants/utilFunctions";
import {LoginData} from "../importedTypes";

const initialState: LoginData = {
  accessExpiresIn: 0,
  accessToken: "",
  refreshExpiresIn: 0,
  refreshToken: "",
  message: "",
  logged_at: new Date(),
  fullname: "",
  role: "",
  userId: "",
}

const authorizationReducer = (state: LoginData = initialState, action: AuthorizationAction) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      setStopFire("userdata", initialState);
      return initialState;
    default:
      return state;
  }
}

export default authorizationReducer;

import React, {useState} from 'react';
import Icon from "../Icon/Icon";
import "./Select.css";

interface ISelectProps {
    options: {
        key: any,
        value: string,
    }[];
    onFinish: Function;
    selected?: number;
    disabled: boolean;
    isSmall?: boolean;
}

const Select = (props: ISelectProps) => {
    const [current, setCurrent] = useState(props.options[props.selected ? props.selected : 0].value);
    const [showOptions, setShowOptions] = useState(false);

    const toggleShow = () => {
        if (!props.disabled)
            setShowOptions(!showOptions);
    }

    return (
        <div className={"selectContainer" + (props.isSmall ? " small" : "")}>
            <div className={"select form-input" + (showOptions ? " is-active" : "")} onClick={() => toggleShow()}>
                <div className="selected-option">{current}</div>
                <div className="control">
                    <Icon icon={"select"}/>
                </div>
            </div>
            <div className={"options" + (showOptions ? "" : " hidden") }>
                {props.options.map((el) =>
                    <div className={"options__option"} key={el.key}
                         onClick={() => {
                             setCurrent(el.value);
                             setShowOptions(false);
                             props.onFinish(el.key);
                         }}>
                        {el.value}
                    </div>)}
            </div>
        </div>
    );
};

export default Select;

import React, {useRef} from "react";
import logo from "../../images/logo.svg";
import logo_small from "../../images/logo_small.svg";
import "./Sidebar.css";
import Button from "../Button/Button";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../state/actions";
import NameBlock from "../NameBlock/NameBlock";

interface ISidebarProps {
    callback?: Function;
}

export const Sidebar = (props: ISidebarProps) => {
    const dispatch = useDispatch();
    const {setIsFull} = bindActionCreators(actionCreators, dispatch);
    const isFull = useSelector((state: any) => state.menu.isFull);

    const img = useRef<HTMLImageElement | null>(null);

    const toggleFull = () => {
        if (img.current)
            img.current.style.transform = `scale(${isFull ? 0.5 : 1})`;

        setTimeout(() => {
            setIsFull(!isFull)

        }, 100);
        setTimeout(() => {
            if (img.current)
                img.current.style.transform = "scale(1)"
        }, 500);
    }

    const logoSrc = isFull ? logo : logo_small;

    return (
        <div className={"sidebar " + (!isFull ? "is-small" : "")}>

            <div className="hidden"/>
            <img src={logoSrc} className={"logo"} alt="logo" ref={img}/>
            <SidebarMenu callback={props.callback}/>

            <NameBlock isOpen={isFull}/>
            <Button className={"hidden-ui"} icon={isFull ? "close" : "open"} onClick={toggleFull}/>
        </div>
    );
};

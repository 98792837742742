import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../state/actions";
import Button from "../Button/Button";
import Select from "../Select/Select";
import {PasswordInput} from "../PasswordInput/PasswordInput";
import {translate} from "../../constants/utilFunctions";
import "./PlayerForm.css";
import {useScale} from "../../state/hooks/useScale";
import {UserRole, User} from "../../api/user_administrator";

interface IPlayerForm {
  isEdit: boolean;
  player?: User;
  onFinish: Function;
  showAlert?: Function;
}

const PlayerForm = (props: IPlayerForm) => {
  let defaultValue = ["", "", ""];
  if (props.player) {
    defaultValue = props.player.fullName.split(" ");
  }

  const {authorization} = useSelector((state: any) => state);
  const isCurrentAdmin = authorization.role === "admin";

  const [fio, setFio] = useState(defaultValue);
  const [role, setRole] = useState(props.player?.userRole ?? (authorization && authorization.role === UserRole.Admin ? UserRole.Moderator : UserRole.User));
  const [password, setPassword] = useState(props.player ? props.player.password : "");
  const scale = useScale(900, 300);


  const dispatch = useDispatch();
  const {createUser, editUser, changePassword} = bindActionCreators(actionCreators, dispatch);

  const setFioPart = (idx: number, value: string) => {
    let res = fio;
    res[idx] = value;
    setFio(res);
  }

  const options = [
    {
      key: "moderator",
      value: "Преподаватель"
    },
    {
      key: "user",
      value: "Ученик"
    }
  ];

  const createUserData = () => {
    let username = translate(fio[0] + fio[1][0] + fio[2][0]);

    const data: any = {
      fullName: fio.join(" "),
      username,
      userRole: role
    };

    return createUser(data);
  }

  const editUserData = () => {
    const data = {
      "new_full_name": fio.join(" "),
      "new_user_role": role,
      "new_user_username": props.player?.username ?? "",
      "userId": props.player?.userId ?? "",
    };

    return editUser(data);
  }

  return (
    <div className={"shadow"}>
      <div className="container player-form" style={{
        transform: `translate(-50%, -50%) scale(${scale})`
      }}>
        <h2>{props.isEdit ? "редактирование" : "добавление"} пользователя</h2>
        <div className="form-component">
          <label>
            <span>ФАМИЛИЯ</span>
            <input type="text" className={"form-input"} placeholder={"фамилия"} defaultValue={fio[0]}
                   onInput={e => setFioPart(0, e.currentTarget.value)}/>
          </label>
          <label>
            <span>ИМЯ</span>
            <input type="text" className={"form-input"} placeholder={"имя"} defaultValue={fio[1]}
                   onInput={e => setFioPart(1, e.currentTarget.value)}/>
          </label>
          <label>
            <span>ОТЧЕСТВО</span>
            <input type="text" className={"form-input"} placeholder={"отчество"} defaultValue={fio[2]}
                   onInput={e => setFioPart(2, e.currentTarget.value)}/>
          </label>

          {props.isEdit && <label><span>NICKNAME</span><input type="text" className={"form-input"} readOnly
                                                              defaultValue={props.player?.username}/></label>}
          {/* @ts-ignore */}
          {props.isEdit && <PasswordInput value={password} onChange={(new_password) => {
            setPassword(new_password);
          }}/>}

          <Select options={options} onFinish={setRole}
                  selected={props.isEdit ? Number(props.player && props.player.userRole === "user") : Number(!isCurrentAdmin)}
                  disabled={isCurrentAdmin ? props.isEdit : true}/>

          {props.isEdit && <Button value={'изменить'} onClick={() => {
            new Promise((resolve) => {
              resolve(editUserData());
            }).then(() => {
              if (props.isEdit && props.player?.password && password !== props.player?.password) {
                changePassword({
                  oldPassword: props.player?.password ?? "",
                  newPassword: password,
                  username: props.player?.username ?? ""
                })
              }
            })
              .then(() => {
                props.onFinish();
              });
          }}/>}
          {!props.isEdit && <Button value={"создать"} onClick={() => {
            new Promise((resolve) => {
              if (fio.join("").length > 2) {
                resolve(createUserData());
              }
            })
              .then((result: any) => {
                if (!props.showAlert)
                  return;

                const data = {
                  login: translate(fio[0] + fio[1][0] + fio[2][0]),
                  password: result.password
                };

                props.showAlert({
                  title: "Данные нового пользователя",
                  html: `<h3>Логин: ${data.login}</h3><h3>Пароль: ${data.password}</h3>`
                })
              })
              .then(() => {
                props.onFinish();
              });
          }}/>}
          <Button value={"отмена"} onClick={() => {
            props.onFinish()
          }}/>
        </div>
      </div>
    </div>
  );
};

export default PlayerForm;

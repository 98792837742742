/* tslint:disable */
/* eslint-disable */
/**
 * Session.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Device } from './Device';
import {
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
} from './Device';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface UserWithDevice
 */
export interface UserWithDevice {
    /**
     * 
     * @type {User}
     * @memberof UserWithDevice
     */
    user?: User;
    /**
     * 
     * @type {Device}
     * @memberof UserWithDevice
     */
    device?: Device;
}

/**
 * Check if a given object implements the UserWithDevice interface.
 */
export function instanceOfUserWithDevice(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserWithDeviceFromJSON(json: any): UserWithDevice {
    return UserWithDeviceFromJSONTyped(json, false);
}

export function UserWithDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWithDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'device': !exists(json, 'device') ? undefined : DeviceFromJSON(json['device']),
    };
}

export function UserWithDeviceToJSON(value?: UserWithDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'device': DeviceToJSON(value.device),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Session.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Config } from './Config';
import {
    ConfigFromJSON,
    ConfigFromJSONTyped,
    ConfigToJSON,
} from './Config';
import type { Coordinate } from './Coordinate';
import {
    CoordinateFromJSON,
    CoordinateFromJSONTyped,
    CoordinateToJSON,
} from './Coordinate';
import type { InitData } from './InitData';
import {
    InitDataFromJSON,
    InitDataFromJSONTyped,
    InitDataToJSON,
} from './InitData';
import type { Size } from './Size';
import {
    SizeFromJSON,
    SizeFromJSONTyped,
    SizeToJSON,
} from './Size';
import type { UserWithDeviceID } from './UserWithDeviceID';
import {
    UserWithDeviceIDFromJSON,
    UserWithDeviceIDFromJSONTyped,
    UserWithDeviceIDToJSON,
} from './UserWithDeviceID';

/**
 * 
 * @export
 * @interface CreateSession
 */
export interface CreateSession {
    /**
     * 
     * @type {Array<Coordinate>}
     * @memberof CreateSession
     */
    activeTemperatureCells?: Array<Coordinate>;
    /**
     * 
     * @type {Config}
     * @memberof CreateSession
     */
    config: Config;
    /**
     * 
     * @type {Array<Array<Array<InitData>>>}
     * @memberof CreateSession
     */
    initialCellsMaterialValues?: Array<Array<Array<InitData>>>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSession
     */
    moderatorIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSession
     */
    name?: string;
    /**
     * 
     * @type {Size}
     * @memberof CreateSession
     */
    size?: Size;
    /**
     * 
     * @type {Array<UserWithDeviceID>}
     * @memberof CreateSession
     */
    userWithDevicesIds?: Array<UserWithDeviceID>;
}

/**
 * Check if a given object implements the CreateSession interface.
 */
export function instanceOfCreateSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "config" in value;

    return isInstance;
}

export function CreateSessionFromJSON(json: any): CreateSession {
    return CreateSessionFromJSONTyped(json, false);
}

export function CreateSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeTemperatureCells': !exists(json, 'active_temperature_cells') ? undefined : ((json['active_temperature_cells'] as Array<any>).map(CoordinateFromJSON)),
        'config': ConfigFromJSON(json['config']),
        'initialCellsMaterialValues': !exists(json, 'initialCellsMaterialValues') ? undefined : json['initialCellsMaterialValues'],
        'moderatorIds': !exists(json, 'moderator_ids') ? undefined : json['moderator_ids'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'size': !exists(json, 'size') ? undefined : SizeFromJSON(json['size']),
        'userWithDevicesIds': !exists(json, 'user_with_devices_ids') ? undefined : ((json['user_with_devices_ids'] as Array<any>).map(UserWithDeviceIDFromJSON)),
    };
}

export function CreateSessionToJSON(value?: CreateSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_temperature_cells': value.activeTemperatureCells === undefined ? undefined : ((value.activeTemperatureCells as Array<any>).map(CoordinateToJSON)),
        'config': ConfigToJSON(value.config),
        'initialCellsMaterialValues': value.initialCellsMaterialValues,
        'moderator_ids': value.moderatorIds,
        'name': value.name,
        'size': SizeToJSON(value.size),
        'user_with_devices_ids': value.userWithDevicesIds === undefined ? undefined : ((value.userWithDevicesIds as Array<any>).map(UserWithDeviceIDToJSON)),
    };
}


import React, {useEffect, useState} from "react";
import GraphInfo from "../GraphInfo/GraphInfo";
import {useSelector} from "react-redux";
import device from "../../images/icons/in_game/vr.svg";

type StatsType = {
  oxygenLeft: number[],
  waterSpent: number[],
  heartRate: number[],
};

const GraphsBlock = ({openDeviceInfo, initialOxygen}: any) => {
  const [stats, setStats] = useState<StatsType>({
    oxygenLeft: [],
    waterSpent: [],
    heartRate: []
  });

  const {currentSession, fireModelPlayer} = useSelector((state: any) => state);

  useEffect(() => {
    if (!fireModelPlayer.fullTime)
      return;

    setStats({
      oxygenLeft: [...stats.oxygenLeft, fireModelPlayer.oxygenLeft / initialOxygen * 100].slice(-15),
      waterSpent: [...stats.waterSpent, fireModelPlayer.waterSpent].slice(-15),
      heartRate: [...stats.heartRate, fireModelPlayer.heartRate].slice(-15)
    });
  }, [fireModelPlayer]);

  let currentSessionData = currentSession.usersWithDevices;
  let username = currentSessionData ? currentSessionData[0].user.fullName : "";

  return (
    <div className="info">
      <div className={"game-info"}>
                <span>{username.replace(/ /, "\n")
                  .split("\n")
                  .map((el: string, i: number) => <React.Fragment key={i}>
                      {el}<br/>
                    </React.Fragment>
                  )}</span>
      </div>
      <div className={"game-info"} onClick={openDeviceInfo}>
        <img src={device} alt="device"/>
        <span>{currentSessionData ? currentSessionData[0].device.deviceIp : ""}</span>
      </div>
      <GraphInfo hasData={true} icon={"oxygen"} value={stats.oxygenLeft[stats.oxygenLeft.length - 1]}
                 data={stats.oxygenLeft}/>
      <GraphInfo hasData={true} icon={"liquid"} value={stats.waterSpent[stats.waterSpent.length - 1]}
                 data={stats.waterSpent}/>
      <GraphInfo hasData={true} icon={"heartRate"} value={stats.heartRate[stats.heartRate.length - 1]}
                 data={stats.heartRate}/>
    </div>
  );
};

export default GraphsBlock;

import "./NameBlock.css";
import {useSelector} from "react-redux";

const defaultName = ["Константинопольский", "Константин", "Иванович"];

const NameBlock = ({isOpen}: any) => {
  const {authorization} = useSelector((state: any) => state);

  let splitted = authorization.fullname ? authorization.fullname.split(" ") : defaultName;
  let surname = splitted[1];
  let surnameInitial = surname ? surname[0] : '';
  let initials = (splitted[0][0] + surnameInitial).toUpperCase();

  return <div className={"nameblock" + (isOpen ? " full" : "")}>
    <div className="initials">{initials}</div>
    <div className="fullname">
      {splitted[0]}<br/>
      {splitted[1]} {splitted[2]}
    </div>
  </div>;
}

export default NameBlock;

import {CREATE_DEVICE, DELETE_DEVICE, EDIT_DEVICE, GET_DEVICES} from "../actionTypes";
import {FindDevices} from "../../api/device";

const initialState: FindDevices = {
    maxPages: 0,
    devices: []
}

const devicesReducer = (state: FindDevices = initialState, action: DeviceAction) => {
    switch (action.type) {
        case GET_DEVICES:
            return {...action.payload};
        case CREATE_DEVICE:
            return state;
        case EDIT_DEVICE:
        case DELETE_DEVICE:
            return {...state, ...action.payload};
        default:
            return state;
    }
}

export default devicesReducer;

/* tslint:disable */
/* eslint-disable */
/**
 * Players Statistics.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../../runtime';
import type {
  CreatePlayersStatisticRequest,
  FindPlayersStatistic200Response,
  ServerError,
} from '../models';
import {
    CreatePlayersStatisticRequestFromJSON,
    CreatePlayersStatisticRequestToJSON,
    FindPlayersStatistic200ResponseFromJSON,
    FindPlayersStatistic200ResponseToJSON,
    ServerErrorFromJSON,
    ServerErrorToJSON,
} from '../models';

export interface CreatePlayersStatisticOperationRequest {
    body?: CreatePlayersStatisticRequest;
}

export interface FindPlayersStatisticRequest {
    userIdFilter?: string;
    sessionIdFilter?: string;
    sessionNameFilter?: string;
    startTimeFilterFrom?: Date;
    startTimeFilterTo?: Date;
    rangeFullTimeFilter?: Array<number>;
    rangeOxygenLeftFilter?: Array<number>;
    rangeWaterSpentFilter?: Array<number>;
    rangeHeartRateFilter?: Array<number>;
    rangeCellSizeFilter?: Array<number>;
    rangeSpeedOfSpreadingFilter?: Array<number>;
    rangeWhenSecondPhaseStartsFilter?: Array<number>;
    rangeCellWaterToSmolderFilter?: Array<number>;
    rangeCellWaterToEmptyFilter?: Array<number>;
    limit?: number;
    page?: number;
}

/**
 * 
 */
export class PlayersStatisticApi extends runtime.BaseAPI {

    /**
     * Create PlayersStatistic
     */
    async createPlayersStatisticRaw(requestParameters: CreatePlayersStatisticOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/player_statistics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePlayersStatisticRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create PlayersStatistic
     */
    async createPlayersStatistic(requestParameters: CreatePlayersStatisticOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPlayersStatisticRaw(requestParameters, initOverrides);
    }

    /**
     * Find Players Statistic
     */
    async findPlayersStatisticRaw(requestParameters: FindPlayersStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindPlayersStatistic200Response>> {
        const queryParameters: any = {};

        if (requestParameters.userIdFilter !== undefined) {
            queryParameters['user_id_filter'] = requestParameters.userIdFilter;
        }

        if (requestParameters.sessionIdFilter !== undefined) {
            queryParameters['session_id_filter'] = requestParameters.sessionIdFilter;
        }

        if (requestParameters.sessionNameFilter !== undefined) {
            queryParameters['session_name_filter'] = requestParameters.sessionNameFilter;
        }

        if (requestParameters.startTimeFilterFrom !== undefined) {
            queryParameters['start_time_filter_from'] = (requestParameters.startTimeFilterFrom as any).toISOString();
        }

        if (requestParameters.startTimeFilterTo !== undefined) {
            queryParameters['start_time_filter_to'] = (requestParameters.startTimeFilterTo as any).toISOString();
        }

        if (requestParameters.rangeFullTimeFilter) {
            queryParameters['range_full_time_filter'] = requestParameters.rangeFullTimeFilter;
        }

        if (requestParameters.rangeOxygenLeftFilter) {
            queryParameters['range_oxygen_left_filter'] = requestParameters.rangeOxygenLeftFilter;
        }

        if (requestParameters.rangeWaterSpentFilter) {
            queryParameters['range_water_spent_filter'] = requestParameters.rangeWaterSpentFilter;
        }

        if (requestParameters.rangeHeartRateFilter) {
            queryParameters['range_heart_rate_filter'] = requestParameters.rangeHeartRateFilter;
        }

        if (requestParameters.rangeCellSizeFilter) {
            queryParameters['range_cell_size_filter'] = requestParameters.rangeCellSizeFilter;
        }

        if (requestParameters.rangeSpeedOfSpreadingFilter) {
            queryParameters['range_speed_of_spreading_filter'] = requestParameters.rangeSpeedOfSpreadingFilter;
        }

        if (requestParameters.rangeWhenSecondPhaseStartsFilter) {
            queryParameters['range_when_second_phase_starts_filter'] = requestParameters.rangeWhenSecondPhaseStartsFilter;
        }

        if (requestParameters.rangeCellWaterToSmolderFilter) {
            queryParameters['range_cell_water_to_smolder_filter'] = requestParameters.rangeCellWaterToSmolderFilter;
        }

        if (requestParameters.rangeCellWaterToEmptyFilter) {
            queryParameters['range_cell_water_to_empty_filter'] = requestParameters.rangeCellWaterToEmptyFilter;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["user_token"] = this.configuration.apiKey("user_token"); // UserAuth authentication
        }

        const response = await this.request({
            path: `/player_statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindPlayersStatistic200ResponseFromJSON(jsonValue));
    }

    /**
     * Find Players Statistic
     */
    async findPlayersStatistic(requestParameters: FindPlayersStatisticRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindPlayersStatistic200Response> {
        const response = await this.findPlayersStatisticRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

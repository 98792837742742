import React from 'react';
import "./MapIndicators.css";
import checkbox from "../../images/icons/in_game/check_big.svg";

const indicators = [
    {
        className: "flame",
        value: "Очаг возгорания"
    },
    {
        className: "stop",
        value: "Тушение"
    },
    {
        className: "water",
        value: "Тушащее вещество"
    },
    {
        className: "player-dot",
        value: "Игрок"
    }
];

interface IMapIndicatorsProps {
    onlyFlame?: boolean;
}

const MapIndicators = (props: IMapIndicatorsProps) => {
    const toRender = props.onlyFlame ? [indicators[0]] : indicators;


    return (
        <div className={"map-indicators"}>
            {
                toRender.map((el, i) => <div className={"indicator"} key={i}>
                    <div className={"box " + el.className}>{props.onlyFlame ? <img src={checkbox} alt={"icon"} /> : ""}</div>
                    <p className={"p1"}>{el.value}</p>
                </div>)
            }
        </div>
    );
};

export default MapIndicators;

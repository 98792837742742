/* tslint:disable */
/* eslint-disable */
/**
 * Fire Model Master Node.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { Config } from './Config';
import {
    ConfigFromJSON,
    ConfigFromJSONTyped,
    ConfigToJSON,
} from './Config';
import type { Coordinate } from './Coordinate';
import {
    CoordinateFromJSON,
    CoordinateFromJSONTyped,
    CoordinateToJSON,
} from './Coordinate';
import type { InitModelPlayersInner } from './InitModelPlayersInner';
import {
    InitModelPlayersInnerFromJSON,
    InitModelPlayersInnerFromJSONTyped,
    InitModelPlayersInnerToJSON,
} from './InitModelPlayersInner';
import type { Matrix } from './Matrix';
import {
    MatrixFromJSON,
    MatrixFromJSONTyped,
    MatrixToJSON,
} from './Matrix';

/**
 * 
 * @export
 * @interface InitModel
 */
export interface InitModel {
    /**
     * 
     * @type {string}
     * @memberof InitModel
     */
    sessionId: string;
    /**
     * 
     * @type {Array<Coordinate>}
     * @memberof InitModel
     */
    activeTemperatureCells: Array<Coordinate>;
    /**
     * 
     * @type {Config}
     * @memberof InitModel
     */
    config: Config;
    /**
     * 
     * @type {Matrix}
     * @memberof InitModel
     */
    matrix: Matrix;
    /**
     * 
     * @type {Array<InitModelPlayersInner>}
     * @memberof InitModel
     */
    players: Array<InitModelPlayersInner>;
}

/**
 * Check if a given object implements the InitModel interface.
 */
export function instanceOfInitModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "activeTemperatureCells" in value;
    isInstance = isInstance && "config" in value;
    isInstance = isInstance && "matrix" in value;
    isInstance = isInstance && "players" in value;

    return isInstance;
}

export function InitModelFromJSON(json: any): InitModel {
    return InitModelFromJSONTyped(json, false);
}

export function InitModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['session_id'],
        'activeTemperatureCells': ((json['active_temperature_cells'] as Array<any>).map(CoordinateFromJSON)),
        'config': ConfigFromJSON(json['config']),
        'matrix': MatrixFromJSON(json['matrix']),
        'players': ((json['players'] as Array<any>).map(InitModelPlayersInnerFromJSON)),
    };
}

export function InitModelToJSON(value?: InitModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_id': value.sessionId,
        'active_temperature_cells': ((value.activeTemperatureCells as Array<any>).map(CoordinateToJSON)),
        'config': ConfigToJSON(value.config),
        'matrix': MatrixToJSON(value.matrix),
        'players': ((value.players as Array<any>).map(InitModelPlayersInnerToJSON)),
    };
}


import {GET_PLAYER_STATISTICS} from "../actionTypes";
import {FindPlayersStatistic200Response} from "../../api/player_statistics";

const initialState: FindPlayersStatistic200Response = {
  maxPages: 0,
  playerStatistics: []
};

const playerStatisticsReducer = (state: FindPlayersStatistic200Response = initialState, action: PlayerStatisticsAction) => {
  switch (action.type) {
    case GET_PLAYER_STATISTICS:
      return {...action.payload};
    default:
      return state;
  }
}

export default playerStatisticsReducer;

import "./Pagination.css";
import React, {useEffect, useState} from "react";
import Select from "../Select/Select";
import Icon from "../Icon/Icon";
import {getStopFire, setStopFire} from "../../constants/utilFunctions";

interface IPaginationProps {
    max_pages: number;
    onChange: Function;
}

export const Pagination = (props: IPaginationProps) => {
    const [limit, setLimit] = useState(getStopFire("paginationLimit"));
    const [paginationPage, setPaginationPage] = useState(1);

    const click = (go: any) => {
        if (go === '+1') {
            setPaginationPage(paginationPage + 1);
        } else if (go === '-1') {
            setPaginationPage(paginationPage - 1)
        } else {
            setPaginationPage(+go)
        }
    }

    useEffect(() => {
        props.onChange(limit, paginationPage - 1);
    }, [paginationPage, limit]);

    const getPages = () => {
        if (!props.max_pages)
            return [1];

        let res = [];
        for (let i = 0; i < props.max_pages; i++) {
            res.push(i + 1); // начиная с 1, а не с 0
        }

        return res;
    }

    return <div className={"pagination"}>
        <Select isSmall={true} options={[{key: 10, value: "10"}, {key: 25, value: "25"}, {key: 50, value: "50"}]}
                selected={[10, 25, 50].indexOf(limit)}
                onFinish={(value: number) => {
                    setStopFire("paginationLimit", value);
                    setLimit(value);
                }} disabled={false}/>
        <div className="content_detail__pagination cdp" data-actpage={paginationPage}>
            {props.max_pages && <span onClick={() => click("-1")}  className="cdp_i clickable">
               <Icon icon={"left"} />
            </span>}

            {getPages().map((el) => <span key={el} onClick={() => click(el)} className={"cdp_i" + (!props.max_pages ? " active" : "")}>{el}</span>)}

            {props.max_pages && <span onClick={() => click("+1")} className="cdp_i clickable">
                <Icon icon={"right"} />
            </span>}
        </div>
    </div>;
}

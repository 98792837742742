/* tslint:disable */
/* eslint-disable */
/**
 * Device.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceIp: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceName: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceToken: string;
}

/**
 * Check if a given object implements the Device interface.
 */
export function instanceOfDevice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deviceId" in value;
    isInstance = isInstance && "deviceIp" in value;
    isInstance = isInstance && "deviceName" in value;
    isInstance = isInstance && "deviceToken" in value;

    return isInstance;
}

export function DeviceFromJSON(json: any): Device {
    return DeviceFromJSONTyped(json, false);
}

export function DeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Device {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['device_id'],
        'deviceIp': json['device_ip'],
        'deviceName': json['device_name'],
        'deviceToken': json['device_token'],
    };
}

export function DeviceToJSON(value?: Device | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'device_ip': value.deviceIp,
        'device_name': value.deviceName,
        'device_token': value.deviceToken,
    };
}

